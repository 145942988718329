import {Button, Modal, Table} from "antd";
import {useStore} from "effector-react";
import {$errorModalState, resetUploadErrorModalEv} from "../../models/uploadModel/index.js";
import {useEvent} from "effector-react/compat";

const UploadPlanErrorModal = () => {
    const {visible, data} = useStore($errorModalState)
    const closeModal = useEvent(resetUploadErrorModalEv)

    const columns = [
        {
            title: 'Номер строки',
            dataIndex: 'row'
        },
        {
            title: 'Столбцы с ошибкой',
            dataIndex: 'col'
        }
    ]

    return <Modal open={visible}
                  width={'80vw'}
                  title={<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 16}}>
                      <span>Ошибки в загруженной таблице</span>
                      <Button className={'btn-secondary'} onClick={() => closeModal()}>
                          Закрыть
                      </Button>
                  </div>}
                  closable={false}
                  footer={null}
    >
        <Table columns={columns} dataSource={data} pagination={false} style={{height: '70vh', overflowY: 'auto'}}/>
    </Modal>
}

export default UploadPlanErrorModal