import {Badge, Button, Dropdown, Tooltip, Typography} from 'antd';
import {NotificationOutlined} from '@ant-design/icons';
import NotificationLink from '../../NotificationLink/NotificationLink.jsx';
import './Dropdown.css'
import {useStore} from 'effector-react';
import {$notifications} from '../../../models/profileModel/index.js';

const Notifications = () => {
    const data = useStore($notifications)

    const dropdownItems = data.length > 0
        ? data?.filter?.(i => i.status === 'new')?.map(i => ({
            key: i.id,
            label: <NotificationLink match_id={i.match_id} />
        }))
        : [{
            key: 'none',
            label: <Typography.Paragraph style={{padding: 12, marginBottom: 0}}>
                Новых уведомлений нет
            </Typography.Paragraph>
        }]

    return <Dropdown overlayClassName={'notification-dropdown'} menu={{items: dropdownItems}}
                     placement={'bottom'}
                     trigger={['click']}
    >
        <Tooltip title={'Уведомления'} placement={'left'}>
            <Badge color={'red'} count={data?.length}>
                <Button className="info-btn" icon={<NotificationOutlined/>}/>
            </Badge>
        </Tooltip>
    </Dropdown>
}

export default Notifications