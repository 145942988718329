import {Outlet, useNavigate} from "react-router-dom";
import ContentContainer from "./components/ContentContainer/ContentContainer.jsx";
import {useEffect} from "react";
import {cookies} from "./api/api.js";
import {useStore} from "effector-react";
import {$authStatus, setAuthStatusEv} from "./models/authModel/index.js";
import {useEvent} from "effector-react/compat";
import {$profileLoaded, AppGate} from "./models/profileModel/index.js";
import locale from "antd/lib/locale/ru_RU";
import {ConfigProvider, Spin} from "antd";

function App() {
    const navigate = useNavigate()
    const authStatus = useStore($authStatus)
    const profileLoaded = useStore($profileLoaded)
    const setAuth = useEvent(setAuthStatusEv)

    useEffect(() => {
        if (!authStatus) {
            const token = cookies.get('access_token')
            if (!token) {
                navigate('/login')
            } else {
                setAuth(true)
            }
        }
    }, [authStatus]);

    return <ConfigProvider locale={locale} theme={{token: {colorPrimary: '#553DB4'}}}>
        <Spin spinning={!profileLoaded} fullScreen size={'large'} tip={'Загрузка профиля'}>
            <ContentContainer>
                {authStatus && <AppGate/>}
                <Outlet/>
            </ContentContainer>
        </Spin>
    </ConfigProvider>
}

export default App
