import {Tabs, Tooltip} from "antd";
import {useEffect, useMemo, useState} from "react";
import {useStore} from "effector-react";
import {$isAllowed} from "../../../models/profileModel/index.js";
import {useSearchParams} from "react-router-dom";
import queryString from "query-string";

const options = ['is_exact', 'is_kp_ved', 'is_tn_ved', 'is_potential']
const MatchesTabs = () => {
    const role = useStore($isAllowed)

    const [search, setSearch] = useSearchParams()

    useEffect(() => {
        if (!(search.get('is_exact') || search.get('is_kp_ved') || search.get('is_tn_ved') || search.get('is_potential'))) {
            onChangeTab('is_exact')
        }
    }, [search]);

    const [tab, setTab] = useState(search.get('is_exact') ? 'is_exact' : search.get('is_kp_ved') ? 'is_kp_ved' : search.get('is_tn_ved') ? 'is_tn_ved' : search.get('is_potential') ? 'is_potential' : 'is_exact')

    const onChangeTab = (tab) => {
        const filter = queryString.parse(search.toString(), {parseBooleans: true, parseNumbers: true})
        for (const key of Object.keys(filter)) {
            if (options.some(k => k === key)) {
                delete filter[key]
            }
            if (key === 'page' || key === 'limit') {
                delete filter[key]
            }
        }

        const newFilter = {...filter, [tab]: true}
        setTab(tab)

        setSearch(newFilter)
    }

    const tabItems = useMemo(() => {
        const items = [
            {
                key: 'is_exact',
                label: <Tooltip title={'Совпадения ЕНСТРУ и с КПВЭД и с ТНВЭД'}>Точные</Tooltip>
            },
            {
                key: 'is_kp_ved',
                label: <Tooltip title={'Совпадения ЕНСТРУ с КПВЭД'}>По КПВЭД</Tooltip>
            },
            {
                key: 'is_tn_ved',
                label: <Tooltip title={'Совпадения ЕНСТРУ с ТНВЭД'}>По ТНВЭД</Tooltip>
            },
        ]

        if (role.supplier || role.admin) {
            items.push(
                {
                    key: 'is_potential',
                    label: <Tooltip title={'Совпадения ЕНСТРУ, добавленные Поставщиком в личном кабинете'}>Потенциальные</Tooltip>
                }
            )
        }

        return items
    }, [role])

    return <Tabs items={tabItems} activeKey={tab} onChange={onChangeTab}/>
}

export default MatchesTabs