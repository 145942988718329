import axios from "axios";
import notification from "antd/es/notification";
import {Cookies} from "react-cookie";
import {setAuthStatusEv} from "../models/authModel/index.js";

// const apiRoot = 'https://gmk.eoz.kz/api'
const apiRoot = __API_URL__;
export const cookies = new Cookies();

export const api = (auth = true) => {

    const axiosInstance = axios.create({
        baseURL: apiRoot,
    })

    if (auth) {
        const access_token = cookies.get('access_token')
        axiosInstance.defaults.headers = {
            'Authorization': `Bearer ${access_token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition'
        }
    }

    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        if (error.request.status === 0) {
            notification.error({
                message: "Ошибка",
                description: 'Ошибка сети',
                placement: 'topRight'
            });
        } else if (error.response.status === 401 && error.config.url === "/token/") {
            cookies.remove('access_token', {path: '/'})
            if (error.response.data.message.includes('blacklist')) {
                notification.error({
                    message: 'Нет доступа',
                    description: 'Вы находитесь в черном списке, обратитесь к Палате для выяснения обстоятельств',
                    placement: 'topRight'
                })
            } else if (error.response.data.message.includes('verified')) {
                notification.error({
                    message: 'Нет доступа',
                    description: 'Ваша регистрация еще не подтверждена, ожидайте решения палаты',
                    placement: 'topRight'
                })
            } else if (error.response.data.message === 'Invalid credentials') {
                notification.error({
                    message: 'Ошибка',
                    description: 'Неверные имя пользователя или пароль',
                    placement: 'topRight'
                })
            } else {
                notification.error({
                    message: error.response.statusText,
                    description: `${error.response.data.message}`,
                    placement: 'topRight'
                });
            }
        } else if (error.response.status === 401) {
            cookies.remove('access_token', {path: '/'})
            setAuthStatusEv(false)
            notification.error({
                message: error.response.statusText,
                description: `${error.response.data.message}`,
                placement: 'topRight'
            });
        } else if (error.response.status === 403) {
            notification.error({
                message: 'Нет доступа',
                description: 'У Вас нет прав на это действие',
                placement: 'topRight'
            });
        } else {
            notification.error({
                message: error.response.statusText,
                description: `${error.response.data.message}`,
                placement: 'topRight'
            });
        }
        return Promise.reject(error);
    });

    return axiosInstance;
}
