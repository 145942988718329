import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import Login from "../pages/LoginReg/Login.jsx";
import Register from "../pages/Register/Register.jsx";
import Lots from "../pages/Lots/Lots.jsx";
import Certificates from "../pages/Certificates/Certificates.jsx";
import ViewLot from "../pages/ViewLot/ViewLot.jsx";
import ViewCert from "../pages/ViewCert/ViewCert.jsx";
import Profile from "../pages/Profile/Profile.jsx";
import RequestStatuses from "../pages/RequestStatuses/RequestStatuses.jsx";
import Matches from "../pages/Matches/Matches.jsx";
import UploadPlan from "../pages/UploadPlan/UploadPlan.jsx";
import Analytics from "../pages/Analytics/Analytics.jsx";
import RegisterRequest from "../pages/Register/RegisterRequest.jsx";
import ProvidersBlackList from "../pages/ProvidersBlackList/ProvidersBlackList.jsx";
import UploadImportReplacement from "../pages/UploadImportReplacement/UploadImportReplacement.jsx";
import RegRequestsApprove from "../pages/RegRequestsApprove/RegRequestsApprove.jsx";
import UploadPlanConfirm from '../pages/UploadPlanConfirm/UploadPlanConfirm.jsx';
import PrioritizationModelForm from '../pages/PrioritizationModelForm/PrioritizationModelForm.jsx';
// import Contracts from "../pages/Contracts/Contracts.jsx";

const routes = createBrowserRouter(
    [
        {
            path: '/',
            element: <App />,
            children: [
                {path: '/profile', element: <Profile />},
                {path: '/view-statuses', element: <RequestStatuses />},
                {path: '/view-statuses/match/:id', element: <RequestStatuses />},
                {path: '/lots', element: <Lots />},
                {path: '/lots/:id', element: <ViewLot />},
                {path: '/certificates', element: <Certificates />},
                {path: '/certificates/:id', element: <ViewCert />},
                {path: '/matches', element: <Matches />},
                {path: '/annual-plan', element: <UploadPlan />},
                {path: '/analytics', element: <Analytics />},
                {path: '/analytics-model', element: <PrioritizationModelForm />},
                // {path: '/contracts', element: <Contracts />},
                {path: '/blacklist', element: <ProvidersBlackList />},
                {path: '/import-replacement', element: <UploadImportReplacement />},
                {path: '/gov-plan-approve', element: <UploadPlanConfirm />},
                {path: '/requests-approve', element: <RegRequestsApprove />},
            ]
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/register',
            element: <Register />
        },
        {
            path: '/register-request',
            element: <RegisterRequest />
        }
    ], {basename: '/'}
)

export default routes