import {Divider, Typography} from 'antd';

const Toast = ({title, message}) => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #f9f9f9',
        width: 'fit-content',
        borderRadius: 6
    }}>
        <Typography.Title level={5} style={{padding: 12, margin: 0}}>{title}</Typography.Title>
        <Divider plain style={{margin: 0}}/>
        <Typography.Text style={{padding: 12}}>{message}</Typography.Text>
    </div>
}

export default Toast