import {$addPosModal, $importSubList, $showUpload} from "./stores.js";
import {addPosFx, excludePosFx, getImportSubListFx, uploadImportSubListFx} from "./effects.js";
import {
    addPosEv,
    ImportSubListGate,
    excludePosEv,
    resetAddModalEv,
    setAddModalEv,
    setShowUploadEv,
    uploadImportSubListEv
} from "./events.js";
import {sample} from "effector";
import notification from "antd/es/notification";
import {$importUploadStatus} from "./stores.js";
import {resetUploadImportEv} from "./events.js";

$importSubList.on(getImportSubListFx.doneData, (_, payload) => payload)
    .reset(ImportSubListGate.close)

$addPosModal.on(setAddModalEv, (_, payload) => payload)
    .reset([addPosFx.doneData, resetAddModalEv])

$showUpload.on(setShowUploadEv, (_, payload) => payload)

$importUploadStatus.on(uploadImportSubListFx.doneData, () => true)
    .reset(resetUploadImportEv)

sample({
    clock: [ImportSubListGate.open, uploadImportSubListFx.doneData, excludePosFx.doneData, addPosFx.doneData],
    target: getImportSubListFx
})

sample({
    clock: uploadImportSubListEv,
    target: uploadImportSubListFx
})

sample({
    clock: excludePosEv,
    target: excludePosFx
})

sample({
    clock: addPosEv,
    target: addPosFx
})

uploadImportSubListFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Перечень импортозамещения загружен'}))
addPosFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Позиция добавлена в перечень импортозамещения'}))
excludePosFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Позиция исключена из перечня импортозамещения'}))