import {Button, Popconfirm, Table, Tooltip} from "antd";
import ViewDetailsModal from "../../ViewDetailsModal/ViewDetailsModal.jsx";
import {openViewModalEv} from "../../../models/viewDetailsModalModel/index.js";
import {useEvent, useStore} from "effector-react";
import {CheckOutlined, CloseOutlined, EyeOutlined, RollbackOutlined} from '@ant-design/icons';
import {
    $blacklist, gmkAddToWhitelistEv,
    gmkExcludeFromWhitelistEv
} from '../../../models/blacklistModel/index.js';

const GmkBlackList = () => {
    const blacklist = useStore($blacklist)

    const openViewModal = useEvent(openViewModalEv)
    const excludeUser = useEvent(gmkAddToWhitelistEv)
    const returnToBlacklist = useEvent(gmkExcludeFromWhitelistEv)

    const columns = [
        {
            title: 'БИН',
            dataIndex: 'gov_id',
        },
        {
            title: 'Исключен из списка',
            dataIndex: 'whitelist_id',
            render: (excluded) => !!excluded && <CheckOutlined style={{color: '#553DB4'}}/>
        },
        {
            width: '5%',
            align: 'center',
            render: (record) => {
                return <Button.Group>
                    <Tooltip title={'Просмотр поставщика'}>
                        <Button
                            className={'btn-primary'}
                            icon={<EyeOutlined/>}
                            onClick={() => openViewModal({type: 'supplier', supplierId: record.gov_id})}
                        />
                    </Tooltip>
                    {record?.whitelist_id
                        ? <Popconfirm title={'Вы уверены, что хотите вернуть этого поставщика в черный список?'}
                                      placement={'left'}
                                      onConfirm={() => returnToBlacklist(record.whitelist_id)}
                                      okText={'Да'}
                                      cancelText={'Нет'}
                        >
                        <Tooltip title={'Вернуть поставщика в черный список'}>
                            <Button className={'btn-danger'} icon={<RollbackOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                        : <Popconfirm title={'Вы уверены, что хотите исключить этого поставщика из черного списка?'}
                                      placement={'left'}
                                      onConfirm={() => excludeUser(record.blacklist_id)}
                                      okText={'Да'}
                                      cancelText={'Нет'}
                        >
                            <Tooltip title={'Исключить поставщика из черного списка'}>
                                <Button className={'btn-danger'} icon={<CloseOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    }
                </Button.Group>
            }
        }
    ]

    return <>
        <ViewDetailsModal />
        <Table columns={columns} dataSource={blacklist} pagination={false}/>
    </>
}

export default GmkBlackList