import {createEvent} from "effector";
import {createGate} from "effector-react";

export const BlackListGate = createGate()

export const setAddModalEv = createEvent()
export const resetAddModalEv = createEvent()

export const addUserEv = createEvent()
export const excludeUserEv = createEvent()

export const gmkAddToWhitelistEv = createEvent()
export const gmkExcludeFromWhitelistEv = createEvent()

export const setShowUploadEv = createEvent()
export const uploadBlackListEv = createEvent()
