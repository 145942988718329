import {Button, Card, Form, Input, Popconfirm, Row} from "antd";
import {useEffect, useState} from "react";
import {
    $isAllowed,
    $passChangeStatus,
    $profile,
    getProfileFx,
    submitEditEv,
    submitEditFx
} from "../../models/profileModel/index.js";
import {useStore} from "effector-react";
import {useEvent} from "effector-react/compat";
import {ChangePasswordForm} from "../../components/ChangePasswordForm/ChangePasswordForm.jsx";
import ProviderPotentialLotForm from "../../components/ProviderPotentialLotForm/ProviderPotentialLotForm.jsx";

const Profile = () => {
    const role = useStore($isAllowed)

    const [form] = Form.useForm()

    const profile = useStore($profile)
    const loading = useStore(getProfileFx.pending)
    const submitLoading = useStore(submitEditFx.pending)
    const changePassStatus = useStore($passChangeStatus)

    const submit = useEvent(submitEditEv)

    const [edit, setEdit] = useState(false)
    const [changePass, setChangePass] = useState(false)

    useEffect(() => {
        if (profile && Object.keys(profile).length > 0) {
            form.setFieldsValue(profile)
        }
    }, [profile]);

    useEffect(() => {
        if (changePassStatus) {
            setChangePass(false)
        }
    }, [changePassStatus]);

    const onSubmit = () => {
        form.submit()
        setEdit(false)
    }

    return <>
        <Card title={'Личный кабинет'} loading={loading} id='personal-profile' style={{marginBottom: 16}}>
            <Form form={form}
                  onFinish={(v) => submit(v)}
                  layout={'vertical'}
            >
                <Form.Item name={'phone_number'} label={'Номер телефона'}>
                    <Input type={'tel'} disabled={!edit}/>
                </Form.Item>
                <Form.Item name={'email'} label={'Электронная почта'}>
                    <Input type={'email'} disabled={!edit}/>
                </Form.Item>
            </Form>
            {changePass && <ChangePasswordForm />}
            <Row style={{gap: 8}}>
                {!edit
                    ? <Button type={'primary'} className={changePass ? '' : 'btn-primary'} onClick={() => setEdit(true)} disabled={changePass}>Редактировать</Button>
                    : <Button className='btn-secondary' onClick={() => setEdit(false)}>Отмена</Button>
                }
                {edit && <Popconfirm title={'Вы уверены, что хотите изменить контактные данные?'}
                                     onConfirm={onSubmit}
                                     cancelText={'Нет'}
                                     okText={'Да'}
                                     okButtonProps={{className: 'btn-primary'}}
                                     cancelButtonProps={{className: 'btn-secondary'}}
                >
                    <Button type={'primary'} className='btn-primary' loading={submitLoading}>
                        Применить изменения
                    </Button>
                </Popconfirm>}
                {!changePass && !edit
                    && <Button onClick={() => setChangePass(true)} type={'primary'} className='btn-secondary'>Сменить пароль</Button>
                }
                {changePass && !edit && <Button className='btn-secondary' onClick={() => setChangePass(false)}>Отмена</Button>}
            </Row>
        </Card>
        {(role.supplier || role.admin) && <ProviderPotentialLotForm />}
    </>
}

export default Profile