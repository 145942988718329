import {useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import queryString from "query-string";

export const usePaginationControl = () => {
    const [search, setSearch] = useSearchParams()

    useEffect(() => {
        if (!search.get('limit')) {
            setLimit(100)
        }
        if (!search.get('page')) {
            setCurPage(1)
        }
    }, [search]);

    const [curPage, setCurPage] = useState(search.get('page') ?? 1)
    const [limit, setLimit] = useState(search.get('limit') ?? 100)

    const onChangePagination = useCallback((page, limit) => {
        const filter = queryString.parse(search.toString(), {parseBooleans: true, parseNumbers: true})
        const pagination = {...filter, page, limit}
        setCurPage(page)
        setLimit(limit)

        setSearch(pagination)
    }, [search, curPage, limit])

    return [curPage, limit, onChangePagination, search]
}