import {Button, Card, Form, Input, Popconfirm, Table, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {
    $profile,
    $submitPotentialLotStatus,
    resetSubmitStatusEv,
    submitPotentialLotEv,
    submitPotentialLotFx
} from "../../models/profileModel/index.js";
import {useEvent, useStore} from "effector-react";
import {CloseOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";

const ProviderPotentialLotForm = () => {
    const [form] = Form.useForm()
    const codes = Form.useWatch('codes', form)
    const valid = (codes?.length > 0 && codes?.every((code) => code)) ?? false

    const {ens_tru} = useStore($profile)

    const dataSource = ens_tru?.map((item, index) => ({key: index, value: item})) ?? []
    const columns = [
        {title: 'Коды ЕНС ТРУ', dataIndex: 'value', key: 'value'},
    ]

    const submit = useEvent(submitPotentialLotEv)
    const resetStatus = useEvent(resetSubmitStatusEv)
    const loading = useStore(submitPotentialLotFx.pending)
    const status = useStore($submitPotentialLotStatus)

    const [showInput, setShowInput] = useState(false)

    const onSubmit = ({codes}) => {submit(codes)}

    const onCancel = () => {
        form.resetFields()
        setShowInput(false)
        resetStatus()
    }

    useEffect(() => {
        if (status === true) {
            onCancel()
        }
    }, [status]);

    return <Card title={'Мои потенциальные лоты'} bodyStyle={{display: 'flex', flexDirection: 'column', gap: 16}}>
        <div>
            <Button onClick={() => showInput ? onCancel() : setShowInput(!showInput)}
                    className={showInput ? 'btn-danger' : 'btn-secondary'}>
                {showInput ? 'Отмена' : 'Добавить позиции'}
            </Button>
        </div>
        {
            showInput && <div style={{width: '60vw', display: 'flex', flexDirection: 'column', gap: 8}}>
                <Tooltip title={
                    <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                        <p>Полный код должен быть следующего формата: 000000.000.000000</p>
                        <p>Можно ввести только первые 6 цифр. Количество цифр после точки может быть любым.</p>
                    </div>
                }
                         placement={'right'}
                >
                    <QuestionCircleOutlined style={{color: '#553DB4', fontSize: 16, maxWidth: 16}}/>
                </Tooltip>
                <Form name={'ens'} form={form} layout={'vertical'} onFinish={onSubmit}>
                    <Form.List name={'codes'}
                               rules={[{
                                   validator: async (_, codes) => {
                                       if (codes.length === 0) {
                                           return Promise.reject(new Error('Поля должны быть не пустыми'))
                                       } else if (codes.length > 0) {
                                           if (!codes.every((code) => code && code.length > 6 && isNumbers(code))) {
                                               return Promise.reject(new Error('Каждый код должен содержать минимум 6 цифр и соответствовать формату - 000000.000.000000'))
                                           }
                                       }
                                   }
                               }]}>
                        {(fields, {add, remove}, {errors}) => (
                            <>
                                {
                                    fields.map((field, index) =>
                                        <Form.Item key={field.key} label={index === 0 ? 'Код ЕНС ТРУ' : ''}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    pattern: /^\d{6}(?:\.\d{1,3})?(?:\.\d{1,6})?$/,
                                                    whitespace: false,
                                                    message: "Код не может быть пустым, должен содержать не менее 6 цифр и соответствовать формату - 000000.000.000000",
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input style={{width: '50%'}} placeholder={'Код ЕНС ТРУ'}/>
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <CloseOutlined
                                                style={{marginLeft: 8}}
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>)}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{width: '20%'}}
                                        className={'btn-secondary'}
                                        icon={<PlusOutlined/>}
                                    >
                                        {fields.length === 0 ? 'Добавить код' : 'Добавить еще'}
                                    </Button>
                                </Form.Item>
                                <Form.ErrorList errors={errors} />
                            </>)}
                    </Form.List>
                </Form>
                <Popconfirm title={`Вы уверены, что ${codes?.length > 1 ? 'коды введены' : 'код введен'} верно?`}
                            onConfirm={() => form.submit()}
                            okText={'Да'}
                            cancelText={'Нет'}
                            disabled={!valid}
                            okButtonProps={{className: 'btn-primary'}}
                            cancelButtonProps={{className: 'btn-secondary'}}
                >
                        <Button className={valid ? 'btn-primary' : ''}
                                loading={loading}
                                disabled={!valid}
                                style={{width: '20%'}}
                        >
                            Подтвердить
                        </Button>
                </Popconfirm>
            </div>
        }
        <Table columns={columns} dataSource={dataSource}/>
    </Card>
}

function isNumbers(string) {
    // const pattern = /^\d+$/;
    const pattern = /^\d{6}(?:\.\d{3})?(?:\.\d{6})?$/;
    return pattern.test(string);
}

export default ProviderPotentialLotForm