import {Flex, Modal, Typography} from "antd";
import {
    $selectedMatch,
    $viewMatchModalState,
    downloadFileEv,
    resetMatchViewModalEv,
    SelectedMatchGate
} from "../../models/matchesModel/index.js";
import {useEvent, useStore} from "effector-react";

const ChamberViewModal = () => {
    const {visible, id} = useStore($viewMatchModalState)
    const match = useStore($selectedMatch)

    const close = useEvent(resetMatchViewModalEv)
    const download = useEvent(downloadFileEv)

    return <>
        {visible && <SelectedMatchGate id={id}/>}
        <Modal open={visible} title={match?.lot?.short_description ?? ''} footer={null} onCancel={() => close()}>
            <Typography.Title level={4}>Комментарий поставщика</Typography.Title>
            <Typography.Paragraph>
                {(match?.comment && match?.comment !== 'undefined')
                ? match.comment : 'Комментарий отсутствует'}
            </Typography.Paragraph>
            <Typography.Title level={4}>Прикрепленные файлы</Typography.Title>
            <Flex vertical gap={'middle'}>
                {match?.files?.length > 0
                    ? match?.files?.map(file => <div
                        key={file.id}
                        className={'link-like'}
                        onClick={() => download({id: file.id, name: file.filename})}
                    >
                        {file?.filename ?? '-'}
                    </div>)
                    : <Typography.Paragraph>Файлы отсутствуют</Typography.Paragraph>
                }
            </Flex>
        </Modal>
    </>
}

export default ChamberViewModal