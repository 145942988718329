import {useStore} from "effector-react";
import {
    $selectedCert,
    $selectedCertSupplier,
    getCertFx, getCertSupplierFx,
    SelectedCertSupplierGate
} from "../../models/certsModel/index.js";
import TabList from "../../components/TabList/TabList.jsx";
import {Card, Modal, Tabs} from "antd";
import {$selectedLot, getLotFx} from "../../models/lotsModel/index.js";
import {$viewModalState, closeViewModalEv} from "../../models/viewDetailsModalModel/index.js";
import {useEvent} from "effector-react/compat";
import TicketForm from "../../components/TicketForm/TicketForm.jsx";

const ViewDetailsModal = () => {
    const {visible, type, supplierId} = useStore($viewModalState)

    const resetModal = useEvent(closeViewModalEv)

    const lot = useStore($selectedLot)
    const cert = useStore($selectedCert)
    const supplier = useStore($selectedCertSupplier)

    const loadingLot = useStore(getLotFx.pending)
    const loadingCert = useStore(getCertFx.pending)
    const loadingUser = useStore(getCertSupplierFx.pending)
    const loading = loadingLot || loadingCert || loadingUser

    const tabs = [
        {
            key: 'cert',
            label: 'Информация о сертификате',
            children: <TabList data={cert} dict={'cert'} exclude={['created_at', 'id', 'gov_id']}/>
        },
        {
            key: 'supplier',
            label: 'Информация о поставщике',
            children: <TabList data={supplier} dict={'supplier'} exclude={excludeSupplier}/>
        }
    ]

    let title
    let cardContent
    switch (type){
        case 'lot': {
            cardContent = <TabList data={lot} dict={'lot'} exclude={excludeFields}/>
            title = `Информация о лоте ${lot?.short_description ?? '-'}`
        } break;
        case 'cert':{
            cardContent = <Tabs defaultActiveKey={'cert'} items={tabs} destroyInactiveTabPane={true}/>;
            title = `Информация о сертификате № ${cert?.cert_id}`
        } break;
        case 'supplier': {
            cardContent = <TabList data={supplier} dict={'supplier'} exclude={excludeSupplier}/>
            title = `Информация о поставщике ${supplier?.name_ru ?? '-'}`
        } break;
        case 'ticket': {
            cardContent = <TicketForm />;
            title = 'Написать жалобу'
        } break;
    }

    return <>
        {(type === 'cert' || type === 'supplier') && <SelectedCertSupplierGate bin={cert?.manufacturer_bin ?? supplierId ?? null}/>}
        <Modal title={title}
               open={visible}
               width={'80vw'}
               onCancel={resetModal}
               destroyOnClose={true}
               footer={null}
        >
            <Card style={{width: '100%', border: 'none', padding: 0, margin: 0, height: '70vh', overflow: "auto"}} loading={loading}>
                {
                    cardContent
                }
            </Card>
        </Modal>
    </>
}

const excludeFields = [
    'id',
    'completed',
    'confirmed',
    'created_at',
    'created_by',
    'updated_at',
    'updated_by',
    'archived',
    'customer_user',
    'have_match',
    'cert_requested'
]

const excludeSupplier = [
    'id',
    'created_at',
    'ens_tru',
    'full_name',
    'organization',
    'division',
    'head',
    'job_title',
    'purpose_of_use',
    'message'
]

export default ViewDetailsModal
