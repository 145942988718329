import {Button, Card, Popconfirm, Table, Tooltip} from "antd";
import {$requestsList, RegRequestsGate, selectRequestEv, verifyRequestEv} from "../../models/regRequestsModel/index.js";
import ViewRequestModal from "./components/ViewRequestModal.jsx";
import {useEvent, useStore} from "effector-react";
import {CheckOutlined, EyeOutlined} from "@ant-design/icons";

const RegRequestsApprove = () => {
    const requests = useStore($requestsList)
    const selectReq = useEvent(selectRequestEv)
    const approve = useEvent(verifyRequestEv)

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'full_name',
            key: 'full_name'
        },
        {
            title: 'Организация',
            dataIndex: 'organization',
            key: 'organization'
        },
        {
            title: 'Цель регистрации',
            dataIndex: 'purpose_of_use',
        },
        {
            key: 'actions',
            render: (record) => <Button.Group>
                <Tooltip title={'Просмотр деталей'}>
                    <Button className={'btn-secondary'} onClick={() => selectReq(record.id)} icon={<EyeOutlined />}/>
                </Tooltip>
                <Popconfirm title={'Вы уверены, что хотите одобрить данную заявку?'}
                            onConfirm={() => approve(record.id)}
                            okText={'Да'}
                            cancelText={'Нет'}
                >
                    <Tooltip title={'Одобрить заявку'}>
                        <Button className={'btn-primary'} icon={<CheckOutlined />} />
                    </Tooltip>
                </Popconfirm>
            </Button.Group>
        }
    ]

    return <Card title={'Заявки на регистрацию'}>
        <RegRequestsGate />
        <ViewRequestModal />
        <Table dataSource={requests} columns={columns} pagination={false}/>
    </Card>
}

export default RegRequestsApprove