import {Button, Col, Row, Table, Tooltip, Typography} from "antd";
import FilterBlock from "../../components/FilterBlock/FilterBlock.jsx";
import {$certsList, CertsListGate, getCertsListFx} from "../../models/certsModel/index.js";
import {useStore} from "effector-react";
import {CaretDownOutlined, CheckOutlined, CloseOutlined, InfoOutlined} from "@ant-design/icons";
import {usePaginationControl} from "../../hooks/pagination-hook.js";
import {useEvent} from "effector-react/compat";
import {openViewModalEv} from "../../models/viewDetailsModalModel/index.js";
import ViewDetailsModal from "../ViewDetailsModal/ViewDetailsModal.jsx";

const Certificates = () => {
    const loading = useStore(getCertsListFx.pending)
    const {count, data} = useStore($certsList)
    const [curPage, limit, onChangePagination, search] = usePaginationControl()

    const openViewModal = useEvent(openViewModalEv)

    const columns = [
        {
            title: 'Действителен',
            key: 'is_valid',
            dataIndex: 'is_valid',
            width: '1%',
            align: 'center',
            render: (valid) => <div style={{width: 24, display: 'inline-block'}}>
                {valid
                    ? <CheckOutlined style={{fontSize: 24, color: '#553DB4'}}/>
                    : <CloseOutlined style={{fontSize: 24, color: '#FFB217'}}/>}
            </div>
        },
        {
            title: 'БИН',
            width: '5%',
            key: 'bin',
            dataIndex: 'manufacturer_bin'
        },
        {
            title: 'Код ТН ВЭД',
            width: '5%',
            key: 'enstru',
            dataIndex: 'tn_ved'
        },
        {
            title: 'Описание товара на рус. яз.',
            key: 'desc_rus',
            width: '20%',
            dataIndex: 'description_ru',
            render: (desc) => <Typography.Paragraph
                ellipsis={{rows: 2, expandable: true, symbol: <CaretDownOutlined style={{color: '#553DB4'}}/>}}
            >
                {desc ?? '-'}
            </Typography.Paragraph>
        },
        {
            title: 'Описание товара на гос. яз.',
            width: '20%',
            key: 'desc_kaz',
            dataIndex: 'description_kz',
            render: (desc) => <Typography.Paragraph
                ellipsis={{rows: 2, expandable: true, symbol: <CaretDownOutlined style={{color: '#553DB4'}}/>}}
            >
                {desc ?? '-'}
            </Typography.Paragraph>
        },
        {
            title: 'Доля местного содержания',
            width: '10%',
            key: 'local',
            dataIndex: 'share_of_local_content'
        },
        {
            width: '5%',
            key: 'actions',
            align: 'center',
            render: (record) => <Tooltip
                title={'Детали'}
                children={
                    <Button className='info-btn'
                            icon={<InfoOutlined/>}
                            onClick={() => openViewModal({id: record.id, type: 'cert'})}
                    />
                }
            />
        }
    ]

    const filters = [
        {name: 'cert_id', label: 'Идентификационный №', type: 'input'},
        {name: 'name', label: 'Наименование', type: 'input'},
        {name: 'manufacturer_name', label: 'Производитель', type: 'input'},
        {name: 'manufacturer_bin', label: 'БИН производителя', type: 'input'},
        {name: 'tn_ved', label: 'Код ТНВЭД', type: 'input'},
        {name: 'kp_ved_code', label: 'Код КП ВЭД', type: 'input'},
        {name: 'region', label: 'Регион', type: 'input'},
    ]

    return <>
        <ViewDetailsModal />
        <CertsListGate search={search.toString()}/>
        <Col span={24}>
            <Row><FilterBlock items={filters}/></Row>
            <Row style={{marginTop: 24}} id='certificates-table'>
                <Table dataSource={data}
                       columns={columns}
                       style={{width: '100%'}}
                       loading={loading}
                       bordered={true}
                       onChange={({current, pageSize}) => onChangePagination(current, pageSize)}
                       pagination={{
                           position: ['bottomCenter'],
                           pageSize: parseInt(limit),
                           current: parseInt(curPage),
                           total: count
                       }}
                />
            </Row>
        </Col>
    </>
}

export default Certificates