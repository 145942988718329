import {createGate} from "effector-react";
import {createEvent} from "effector";

export const AppGate = createGate()

export const ReqStatusesGate = createGate()

export const submitEditEv = createEvent()

export const changePasswordEv = createEvent()

export const setMatchModalState = createEvent()
export const resetMatchModalEv = createEvent()

export const sendMatchMessageEv = createEvent()
export const downloadMatchCommentFileEv = createEvent()

export const submitPotentialLotEv = createEvent()
export const resetSubmitStatusEv = createEvent()

export const markNotifAsReadEv = createEvent()

export const deleteLocalLotsEv = createEvent()
export const deleteImportedLotsEv = createEvent()