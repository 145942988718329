import {createEffect} from "effector";
import {api} from "../../api/api.js";

export const previewPlanFx = createEffect()
    .use(async (file) => {
        const config = {headers: {"Content-Type": "multipart/form-data"}}
        const data = new FormData
        data.append('file', file.originFileObj)

        return (await api().post(`/lots/preview`, data, config)).data
    })

export const previewPlanImportFx = createEffect()
    .use(async (file) => {
        const config = {headers: {"Content-Type": "multipart/form-data"}}
        const data = new FormData
        data.append('file', file.originFileObj)

        return (await api().post(`/lots/preview`, data, config)).data
    })

export const confirmPlanFx = createEffect()
    .use(async (file) => {
        const config = {headers: {"Content-Type": "multipart/form-data"}}
        const data = new FormData
        data.append('file', file.originFileObj)

        return (await api().post(`/lots/upload-local`, data, config)).data
    })

export const confirmPlanImportFx = createEffect()
    .use(async (file) => {
        const config = {headers: {"Content-Type": "multipart/form-data"}}
        const data = new FormData
        data.append('file', file.originFileObj)

        return (await api().post(`/lots/upload-imported`, data, config)).data
    })


export const govGetGmkListFx = createEffect()
    .use(async () => (await api().get('/users/customers')).data)

export const govGetPlanPerGmkFx = createEffect()
    .use(async (gov_id) => (await api().get(`/lots/?customer_id=${gov_id}`)).data)

export const govApprovePlanFx = createEffect()
    .use(async (user_id) => (await api().post(`/lots/confirm-local/${user_id}`)).data)

export const govApprovePlanImportFx = createEffect()
    .use(async (user_id) => (await api().post(`/lots/confirm-imported/${user_id}`)).data)