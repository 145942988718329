import {Modal, Form, Upload, Input, Button, Row, Popconfirm} from "antd";
import {ArrowDownOutlined, QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';
import {useEvent, useStore} from "effector-react";
import {
    $approveModalState,
    approveByProviderEv,
    approveByProviderFx,
    resetApproveModal
} from "../../models/matchesModel/index.js";
import {useEffect} from "react";

const ProviderApproveModal = () => {
    const [form] = Form.useForm()

    const submit = useEvent(approveByProviderEv)
    const close = useEvent(resetApproveModal)
    const loading = useStore(approveByProviderFx.pending)

    const {visible, id, title, status} = useStore($approveModalState)

    useEffect(() => {
        if (status === true) {
            onCancel()
        }
    }, [status]);

    const onConfirm = ({comment, kp, techdoc, other_files}) => {
        const payload = {comment}
        if (!kp?.file) {
            return
        }
        if (!techdoc?.file) {
            return
        }

        payload['files'] = [kp.file, techdoc.file]
        if (other_files?.fileList && other_files?.fileList.length > 0) {
            other_files.fileList.forEach(f => payload['files'].push(f.originFileObj))
        }

        submit({id: id, values: payload})
    }

    const onCancel = () => {
        form.resetFields()
        close()
    }

    return <Modal title={`Подтверждение участия в лоте ${title}`} width={'80vw'} footer={null} onCancel={onCancel} open={visible}>
        <Form form={form} layout={'vertical'} onFinish={(values) => onConfirm(values)}>
            <Form.Item label={'Комментарий'} name={'comment'}>
                <Input.TextArea/>
            </Form.Item>
            <div style={{display: 'flex', gap: 8}}>
                <div style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: 6,
                    padding: '8px 16px',
                    display: 'flex',
                    gap: 8,
                    marginBottom: 16
                }}>
                <ArrowDownOutlined style={{color: '#553DB4', fontSize: 16}}/>
                    <span>Пожалуйста, приложите файлы ниже, строго в указанном порядке</span>
                </div>
            </div>
            <Form.Item label={'Коммерческое предложение'} name={'kp'}
                       rules={[{
                           required: true,
                           message: 'Пожалуйста, приложите коммерческое предложение'
                       }]}
            >
                <Upload beforeUpload={() => false} multiple={false}>
                    <Button className={'btn-secondary'} icon={<UploadOutlined/>} size={'large'}
                            style={{width: '10vw'}}/>
                </Upload>
            </Form.Item>
            <Form.Item label={'Техническая документация'} name={'techdoc'}
                       rules={[{
                           required: true,
                           message: 'Пожалуйста, приложите техническую документацию'
                       }]}
            >
                <Upload beforeUpload={() => false} multiple={false}>
                    <Button className={'btn-secondary'} icon={<UploadOutlined/>} size={'large'}
                            style={{width: '10vw'}}/>
                </Upload>
            </Form.Item>
            <Form.Item label={'Другие файлы'} name={'other_files'}>
                <Upload beforeUpload={() => false} multiple={true}>
                    <Button className={'btn-secondary'} icon={<UploadOutlined/>} size={'large'}
                            style={{width: '10vw'}}/>
                </Upload>
            </Form.Item>
            <div style={{display: 'flex', gap: 8}}>
                <QuestionCircleOutlined style={{color: '#553DB4', fontSize: 16}}/>
                <span>Вы можете загрузить дополнительные файлы, которые вы считаете необходимыми для подачи заявки</span>
            </div>
        </Form>
        <Row style={{gap: 8, marginTop: 16}} justify={'end'}>
            <Popconfirm
                title={'Вы подтверждаете участие в данном лоте? Данное действие необратимо.'}
                onConfirm={() => form.submit()}
                okText={'Да'}
                        cancelText={'Нет'}
                        okButtonProps={{className: 'btn-primary'}}
                        cancelButtonProps={{className: 'btn-secondary'}}
            >
                <Button className={'btn-primary'} loading={loading}>Подтвердить</Button>
            </Popconfirm>
            <Button className={'btn-danger'} onClick={onCancel}>Отмена</Button>
        </Row>
    </Modal>
}

export default ProviderApproveModal