import {Card, Tabs} from "antd";
import {useStore} from "effector-react";
import {$isAllowed, getReqStatusesFx, ReqStatusesGate} from "../../models/profileModel";
import CertsRequestsTable from "./components/CertsRequestsTable.jsx";
import MatchesStatusesTable from "./components/MatchesStatusesTable.jsx";
import {useMemo} from 'react';
import ViewContractStepsModal from "../ViewMatchStepsModal/ViewContractStepsModal.jsx";
import MatchChatModal from '../../components/MatchChatModal/MatchChatModal.jsx';

const RequestStatuses = () => {
    const loading = useStore(getReqStatusesFx.pending)
    const perms = useStore($isAllowed)

    const tabs = useMemo(() => {
        const t = [
            {
                key: 'matches',
                label: 'Совпадения',
                children: <MatchesStatusesTable />
            }
        ]

        if (perms.supplier || perms.admin) {
            t.unshift(
                {
                    key: 'cert',
                    label: 'Сертификаты',
                    children: <CertsRequestsTable />
                }
            )
        }

        return t
    }, [perms])



    return <>
        <ReqStatusesGate />
        <ViewContractStepsModal />
        <MatchChatModal />
        <Card title={'Статусы моих заявок'} loading={loading}>
            <Tabs defaultActiveKey={'matches'} items={tabs} destroyInactiveTabPane={true}/>
        </Card>
    </>
}

export default RequestStatuses