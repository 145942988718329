import styles from './StatusBadge.module.css'
import {Popover} from "antd";

const getStatusTitle = function (status, cert) {
    if (status === 'new') {
        return cert ? 'Новая заявка' : 'Новое совпадение'
    } else if (status === 'in_progress') {
        return 'В процессе'
    } else if (status === 'rejected') {
        return 'Отказано'
    } else if (status === 'canceled') {
        return 'Отменено'
    } else if (status === 'completed' || status === 'approved') {
        return cert ? 'Одобрено' : 'Завершено'
    }
}

const getDetails = function (appProv, appCust) {
    const prov = appProv ? 'Одобрено Поставщиком' : appProv === false ? 'Отказано Поставщиком' : 'Нет ответа от Поставщика'
    const cust = appCust ? 'Одобрено Закупщиком' : appCust === false ? 'Отказано Закупщиком' : 'Нет ответа от Закупщика'
    // const cham = appChamber ? 'Одобрено НПП' : appProv === false ? 'Отказано НПП' : 'Нет ответа от НПП'

    return <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <span>{prov}</span>
        <span>{cust}</span>
        {/*<span>{cham}</span>*/}
    </div>
}

const StatusBadge = ({status, cert, appProv, appCust}) => {

    return <Popover
        title={getStatusTitle(status, cert)}
        placement={'topLeft'}
        content={!cert ? getDetails(appProv, appCust) : ''}
        children={<div className={`${styles.badge} ${styles[status]}`} />}
    />
}

export default StatusBadge