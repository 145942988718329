import {Button, Card, Form, Input, Space, Tooltip, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useEvent} from "effector-react/compat";
import {$regSuccess, $signedCert, authorizeDSEv, completeRegEv, RegisterGate} from "../../models/authModel/index.js";
import {useStore} from "effector-react";
import {useEffect, useState} from "react";

const Register = () => {
    const onRegisterDS = useEvent(authorizeDSEv)
    const onConfirm = useEvent(completeRegEv)
    const signedCert = useStore($signedCert)
    const regStatus = useStore($regSuccess)

    const navigate = useNavigate()

    useEffect(() => {
        if (regStatus) {
            navigate('/login')
        }
    }, [regStatus]);

    const [passValid, setPassValid] = useState(false);
    const [passForm] = Form.useForm()

    const validPass = new RegExp('^(?=.*[a-zA-Z0-9_!@#$%^&?*])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&?*])[a-zA-Z0-9_!@#$%^&?*]{8,32}$')

    const onSubmit = async (pass) => {
        onConfirm({cert: signedCert, password: pass})
    }

    return <>
        <RegisterGate/>
        <Card className={'login-form'} bodyStyle={{textAlign: "center"}}>
            <Typography.Paragraph>
                Убедитесь, что у Вас установлено и запущено приложение <Link to={'https://ncl.pki.gov.kz/'}
                                                                             target={'_blank'}>NCA Layer</Link>
            </Typography.Paragraph>
            <Form form={passForm} layout={'vertical'} onFinish={(v) => onSubmit(v.password)}>
                <Form.Item name={'password'} label={'Придумайте пароль'}
                           rules={[
                               {required: true, message: 'Введите пароль'},
                               {
                                   pattern: validPass,
                                   message: 'Пароль должен содержать символы a-z, A-Z, 0-9, _!@#$%^&?*, длина 8-32'
                               },
                           ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item name={'confirm_password'} label={'Подтвердите пароль'}
                           dependencies={['password']}
                           rules={[
                               {required: true, message: 'Подтвердите пароль'},
                               ({getFieldValue}) => ({
                                   validator(_, value) {
                                       if (getFieldValue('password') === value) {
                                           setPassValid(true)
                                           return Promise.resolve();
                                       } else {
                                           setPassValid(false)
                                           return Promise.reject(new Error('Пароли не совпадают'));
                                       }
                                   },
                               }),
                           ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
            <Space direction={'vertical'}>
                <Button onClick={onRegisterDS} type={'primary'} disabled={signedCert}>
                    Зарегистрировать ЭЦП
                </Button>
                <Tooltip title={
                    passValid && signedCert
                        ? ''
                        : 'Чтобы заврешить регистрацию Вам необходимо придумать пароль и авторизоваться через ЭЦП'
                }
                         placement={'bottom'}>
                    <Button onClick={() => passForm.submit()} disabled={!passValid || !signedCert}>
                        Завершить регистрацию
                    </Button>
                </Tooltip>
            </Space>
        </Card>
    </>
}

export default Register