import {useEvent, useStore} from 'effector-react';
import {
    $modelModalState,
    $modelResult, $resultsSelect, $selectedResult,
    resetModelModalEv, selectResultEv
} from '../../models/analyticsModel/index.js';
import {Modal, Select, Space} from 'antd';
import TabList from '../../components/TabList/TabList.jsx';

const PrioritizationResultsModal = () => {
    const results = useStore($modelResult)
    const {open} = useStore($modelModalState)
    const resetModal = useEvent(resetModelModalEv)
    const selectOpts = useStore($resultsSelect)
    const selectResult = useEvent(selectResultEv)
    const selectedResult = useStore($selectedResult)

    return <Modal open={open} footer={null} onCancel={resetModal} width={'90vw'}
                  title={'Результат рассчета модели приоритизации'}
                  destroyOnClose={true}
    >
        <Space direction={'horizontal'} style={{marginBottom: 16}}>
            <Select options={selectOpts} onSelect={selectResult} style={{width: 300}}/>
            <span>{`Всего товаров: ${results?.length}`}</span>
        </Space>
        {selectedResult && <TabList data={selectedResult} dict={'prioritization'} exclude={[]}/>}
    </Modal>
}

export default PrioritizationResultsModal