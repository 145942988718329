import {Card} from "antd";
import TabList from "../../components/TabList/TabList.jsx";
import {$selectedLot, getLotFx, SelectedLotGate} from "../../models/lotsModel/index.js";
import {useLocation} from "react-router-dom";
import {useStore} from "effector-react";

const ViewLot = () => {

    const loading = useStore(getLotFx.pending)

    const lot = useStore($selectedLot)

    const location = useLocation()

    return <>
        <SelectedLotGate id={parseInt(location.pathname.split('/').at(-1))}/>
        <Card style={{width: '100%'}} loading={loading} title={`Информация о лоте ${lot?.short_description ?? '-'}`}>
            <TabList data={lot} dict={'lot'} exclude={excludeFields}/>
        </Card>
    </>
}

const excludeFields = [
    'id',
    'completed',
    'confirmed',
    'created_at',
    'created_by',
    'updated_at',
    'updated_by',
    'archived',
    'customer_user',
    'have_match'
]

export default ViewLot