import {Layout, Space} from 'antd';
import logo from '../../assets/main_logo.svg'
import './ContentContainer.css'
import ProfileDropdown from './components/ProfileDropdown.jsx';
import NavMenu from './components/NavMenu.jsx';
import Notifications from './components/Notifications.jsx';
import {$isAllowed} from '../../models/profileModel/index.js';
import {useStore} from 'effector-react';
const { Footer, Header } = Layout;

const ContentContainer = ({children}) => {
    const perms = useStore($isAllowed)

    return <div><Layout className={'container'} id='header'>
        <Header style={{
            position: 'fixed',
            top: 0,
            zIndex: 25,
            width: '100%',
            display: 'flex', alignItems: 'center', justifyContent: "space-between",
            backgroundColor: '#4328af',
            border: '1px solid rgba(5, 5, 5, 0.06)'
        }}>
            <img style={{marginRight: '25px'}} alt={'logo'} src={logo}/>
            <NavMenu />
            <Space direction={'horizontal'} size={'large'}>
                {(perms.supplier || perms.buyer) && <Notifications/>}
                <ProfileDropdown/>
            </Space>
        </Header>
        <Layout.Content
          style={{
            marginTop: '70px'
          }}
          className={'content'}>
            {children}
        </Layout.Content>
        <Footer style={{backgroundColor:'#0C0F11'}}>
          <span style={{color:'gray'}}>© 2023 Единое окно закупок. Все права защищены</span>
        </Footer>
    </Layout>
    </div>
}

export default ContentContainer