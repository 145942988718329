import {createEffect} from "effector";
import {api} from "../../api/api.js";

export const getImportSubListFx = createEffect()
    .use(async () => (await api().get('/import-substitutions/')).data)

export const uploadImportSubListFx = createEffect()
    .use(async (file) => {
        const config = {headers: {"Content-Type": "multipart/form-data"}}
        const data = new FormData
        data.append('file', file.originFileObj)

        return (await api().post(`/import-substitutions/upload`, data, config)).data
    })

export const addPosFx = createEffect()
    .use(async (ens_tru) => (await api().post('/import-substitutions/', {ens_tru})).data)

export const excludePosFx = createEffect()
    .use(async (id) => (await api().delete(`/import-substitutions/${id}`)).data)