import {Button, Tooltip} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useStore} from "effector-react";
import {downloadFilteredMatchesFx} from "../../models/matchesModel/index.js";

const DownloadFilteredButton = ({onDownload}) => {
    const donwloading = useStore(downloadFilteredMatchesFx.pending)

    return <Tooltip title={'Скачать выгрузку по примененным фильтрам'} placement={'right'}>
        <Button className={'btn-primary'} icon={<DownloadOutlined />} onClick={() => onDownload()} loading={donwloading}/>
    </Tooltip>
}

export default DownloadFilteredButton