import {Button, Card, Divider, Flex, Modal, Popover, Typography} from 'antd';
import {useEvent, useStore} from 'effector-react';
import {
    $isAllowed,
    $matchChatModalState,
    $selectedMatchDets, $selectedMatchProvider, getMatchDetsFx,
    resetMatchModalEv
} from '../../models/profileModel/index.js';
import {DownloadOutlined, IdcardOutlined} from '@ant-design/icons';
import MessagesBlock from './components/MessagesBlock.jsx';
import {useNavigate} from 'react-router-dom';
import {downloadFileEv} from '../../models/matchesModel/index.js';
import TabList from '../TabList/TabList.jsx';

const MatchChatModal = () => {
    const navigate = useNavigate()
    const perms = useStore($isAllowed)
    const providerInfo = useStore($selectedMatchProvider)

    const {open} = useStore($matchChatModalState)
    const closeModal = useEvent(resetMatchModalEv)
    const match = useStore($selectedMatchDets)
    const loading = useStore(getMatchDetsFx.pending)
    const downloadMatchFile = useEvent(downloadFileEv)

    const onClose = () => {
        navigate('/view-statuses')
        closeModal()
    }

    return <Modal open={open}
                  title={match?.lot?.short_description
                      ? `Детали лота ${match?.lot?.short_description}`
                      : ''}
                  onCancel={() => onClose()}
                  footer={
                      <Button className={'btn-secondary'} onClick={() => onClose()}>
                          Закрыть
                      </Button>
                  }
                  destroyOnClose={true}
                  width={'90%'}
    >
        <Typography.Title level={5}>
            Комментарий поставщика
            {perms.buyer &&
                <Popover content={<TabList data={providerInfo} dict={'supplier'} exclude={excludeSupplier}/>}
                         title={'Информация о поставщике'} placement={'bottom'}
                         trigger={['click']}
                >
                    <IdcardOutlined style={{fontSize: 20, marginLeft: 8}} className={'link-like'}/>
                </Popover>
            }
        </Typography.Title>
        <Typography.Paragraph>
            {(match?.comment && match?.comment !== 'undefined')
                ? match.comment : 'Комментарий отсутствует'}
        </Typography.Paragraph>
        <Divider/>
        <Card title={'Файлы прикрепленные к заявке'} size={'small'} style={{marginTop: 16}} loading={loading}>
            <Flex vertical gap={'middle'}>
                {match?.files?.length > 0
                    ? match?.files?.map(file => <div
                        key={file.id}
                        className={'link-like'}
                        onClick={() => downloadMatchFile({id: file.id, name: file.filename})}
                    >
                        <DownloadOutlined style={{marginRight: 8}}/>
                        {file?.filename ?? '-'}
                    </div>)
                    : <Typography.Paragraph>Файлы отсутствуют</Typography.Paragraph>
                }
            </Flex>
        </Card>
        <MessagesBlock loading={loading}/>
    </Modal>
}

const excludeSupplier = [
    'id',
    'created_at',
    'ens_tru',
    'full_name',
    'organization',
    'division',
    'head',
    'job_title',
    'purpose_of_use',
    'message',
    'fot_tax_2020',
    'fot_tax_2021',
    'fot_tax_2022',
    'fot_tax_2023',
    'tax_2020',
    'tax_2021',
    'tax_2022',
    'tax_2023',
    'number_of_employees_2020',
    'number_of_employees_2021',
    'number_of_employees_2022',
    'number_of_employees_2023'
]

export default MatchChatModal