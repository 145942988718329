import Card from "antd/es/card";
import DatePicker from "antd/es/date-picker";
import {Badge, Button, Col, ConfigProvider, Form, Input, Row, Select} from "antd";
import dayjs from "antd/node_modules/dayjs";
import updateLocale from 'antd/node_modules/dayjs/plugin/updateLocale'
import 'antd/node_modules/dayjs/locale/ru.js'
import {useEvent, useStore} from "effector-react";
import {$isAllowed} from "../../../models/profileModel/index.js";
import './StepCard.css'
import {createStatusEv, updateStatusEv} from "../../../models/contractsModel/index.js";
import locale from 'antd/lib/locale/ru_RU'
import {useState} from "react";

dayjs.extend(updateLocale)
dayjs.locale('ru')
dayjs.updateLocale('ru', {weekStart: 1})


const StepCard = ({stage, options, disabled, contractId}) => {
    const createStatus = useEvent(createStatusEv)
    const updateStatus = useEvent(updateStatusEv)

    const [form] = Form.useForm()
    const selectedStatus = Form.useWatch('status_id', form)

    const [formUpdated, setFormUpdated] = useState(false)

    const perms = useStore($isAllowed)

    const onCreateStatus = (values) => {
        const payload = {...values, deadline: dayjs(values.deadline).format('YYYY-MM-DD'), contract_id: contractId}
        return createStatus(payload)
    }

    const onUpdateStatus = (values) => {
        const payload = {...values, deadline: dayjs(values.deadline).format('YYYY-MM-DD'), id: stage.id}
        return updateStatus(payload)
    }


    const {badgeColor, badgeText} = checkBadge(stage)

    return <ConfigProvider locale={locale}>
        <Card title={stage?.stage_name ?? ''}
                 style={{width: '80%'}}
                 extra={<Badge color={badgeColor} text={badgeText} />}
                 className={disabled ? 'card-disabled' : ''}
    >
        <Form form={form} layout={'vertical'}
              onFinish={(values) => {
                  if (formUpdated && stage.status_id !== 0) {
                      return onUpdateStatus(values)
                  } else {
                      return onCreateStatus(values)
                  }
              }}
              onFieldsChange={(changedFields) => {
                  const fieldName = changedFields[0].name[0]
                  let value = changedFields[0].value
                  if (fieldName === 'deadline') {
                      value = dayjs(value).format('YYYY-MM-DD')
                  }
                  if (Object.hasOwn(stage, fieldName) && stage[fieldName] !== value) {
                      setFormUpdated(true)
                  } else if (!Object.hasOwn(stage, fieldName) && value) {
                      setFormUpdated(true)
                  }
              }}
        >
            <Row justify={'space-between'}>
                <Col span={11}>
                    <Form.Item label={'Статус'} name={'status_id'}
                               initialValue={stage?.status_id ?? null}
                               required={true}
                               rules={[{required: true, message: 'Выберите статус'}]}
                    >
                        <Select style={{width: '100%'}}
                                disabled={disabled || stage?.status_id !== 0 || perms.chamber}
                                options={options}
                        />
                    </Form.Item>
                </Col>

                <Col span={11}>
                    <Form.Item label={'Срок'} name={'deadline'}
                               initialValue={stage?.deadline ? dayjs(stage.deadline) : null}
                               rules={[{required: true, message: 'Укажите срок'}]}
                    >

                        <DatePicker style={{width: '100%'}} placeholder={'Выберите дату'}
                                    disabled={disabled || stage?.deadline || perms.chamber}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={'Комментарий Поставщика'} name={'comment'}
                               initialValue={stage?.comment ?? ''}
                    >
                        <Input.TextArea disabled={disabled || perms.buyer || perms.chamber} rows={2}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        {(selectedStatus !== 0 && formUpdated && !disabled) && <Row>
            <Button className={disabled ? '' : 'info-btn'} disabled={disabled} onClick={() => form.submit()}>
                Подтвердить
            </Button>
        </Row>}
    </Card>
    </ConfigProvider>
}

export default StepCard


function checkBadge(stage) {
    if (stage?.stage_name === 'Заключение контракта' && !stage?.is_success && stage.status_id === 0) {
        return {badgeColor: 'rgba(255,255,0,0.6)', badgeText: 'Новый контракт'}
    } else if (stage?.disabled) {
        return {badgeColor: 'lightgrey', badgeText: 'Предыдущий этап не завершен'}
    } else if (!stage?.disabled && (stage?.is_success === null || stage?.is_success === undefined)) {
        return {badgeColor: 'yellow', badgeText: 'Ожидание статуса'}
    } else if (stage?.is_success === false) {
        return {badgeColor: 'red', badgeText: 'Отменено Поставщиком'}
    } else if (stage?.is_success === true) {
        return {badgeColor: '#00ff00', badgeText: 'Этап завершен'}
    }
}