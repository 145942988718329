export const previewColumns = [
    {
        title: 'Код ЕНС ТРУ',
        key: 'enstru',
        dataIndex: 'ens_tru'
    },
    {
        title: 'Краткое описание',
        key: 'name',
        dataIndex: 'short_description'
    },
    {
        title: 'Длинное описание (при наличии)',
        key: 'long_desc',
        dataIndex: 'long_description'
    },
    {
        title: 'Количество',
        key: 'count',
        dataIndex: 'count'
    },
    {
        title: 'Стоимость за ед.',
        key: 'unit_price',
        dataIndex: 'item_price'
    },
    {
        title: 'Итоговая сумма',
        key: 'total',
        dataIndex: 'total_price'
    },
    {
        title: 'Желаемая дата поставки',
        key: 'dd',
        dataIndex: 'delivery_date'
    }
]