import {Button, Card} from "antd";
import UploadBlackList from "./components/UploadBlackList.jsx";
import ManageBlackList from "./components/ManageBlackList.jsx";
import {useEvent, useStore} from "effector-react";
import {$showUpload, BlackListGate, setShowUploadEv} from "../../models/blacklistModel/index.js";
import {$isAllowed} from '../../models/profileModel/index.js';
import GmkBlackList from './components/GmkBlacklist.jsx';

const ProvidersBlackList = () => {
    const perms = useStore($isAllowed)

    const showUpload = useStore($showUpload)
    const setShowUpload = useEvent(setShowUploadEv)

    return <Card title={'Черный список поставщиков'}>
        <BlackListGate />
        {(perms.admin || perms.chamber)
            && <Button type={'primary'} onClick={() => setShowUpload(!showUpload)}
                 style={{marginBottom: 16}}>
            {showUpload ? 'Отмена' : 'Загрузить список'}
        </Button>}
        {(showUpload && perms.chamber) && <UploadBlackList />}
        {(perms.admin || perms.chamber) && <ManageBlackList/>}
        {perms.buyer && <GmkBlackList />}
    </Card>
}

export default ProvidersBlackList