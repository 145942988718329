import {sample} from "effector";
import {$approveModalState, $matchesList, $selectedMatch, $viewMatchModalState} from "./stores.js";
import {
    getMatchFx,
    getMatchesListFx,
    approveByProviderFx,
    approveByCustomerFx,
    approveByChamberFx,
    requestCertFx, rejectByChamberFx, rejectByCustomerFx, rejectByProviderFx, downloadFileFx, downloadFilteredMatchesFx
} from "./effects.js";
import {
    approveByCustomerEv,
    approveByChamberEv,
    approveByProviderEv,
    MatchesListGate,
    rejectByCustomerEv,
    rejectByChamberEv,
    rejectByProviderEv,
    requestCertEv,
    SelectedMatchGate,
    setApproveModalState,
    resetApproveModal,
    resetMatchViewModalEv,
    selectMatchEv,
    downloadFileEv,
    downloadFilteredMatchesEv
} from "./events.js";
import notification from "antd/es/notification";

$matchesList.on(getMatchesListFx.doneData, (state, payload) => payload)
    .reset(MatchesListGate.close)

$selectedMatch.on(getMatchFx.doneData, (state, payload) => payload)
    .reset(SelectedMatchGate.close)

$approveModalState.on(setApproveModalState, (state, payload) => ({...state, ...payload}))
    .on(approveByProviderFx.doneData, (state) => ({...state, status: true}))
    .reset(resetApproveModal)

$viewMatchModalState
    .on(selectMatchEv, (state, payload) => ({visible: true, id: payload}))
    .reset(resetMatchViewModalEv)

sample({
    clock: MatchesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search') && (gate?.search?.includes('is_exact') || gate?.search?.includes('is_kp_ved') || gate?.search?.includes('is_tn_ved') || gate?.search?.includes('is_potential')),
    fn: (gate) => gate.search,
    target: getMatchesListFx
})

sample({
    source: MatchesListGate.state,
    clock: [approveByChamberFx.doneData, approveByProviderFx.doneData, approveByCustomerFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search') && (gate?.search?.includes('is_exact') || gate?.search?.includes('is_kp_ved') || gate?.search?.includes('is_tn_ved') || gate?.search?.includes('is_potential')),
    fn: (gate) => gate.search,
    target: getMatchesListFx
})

sample({
    clock: SelectedMatchGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id') && !!gate.id,
    fn: (gate) => gate.id,
    target: getMatchFx
})

sample({
    clock: approveByProviderEv,
    target: approveByProviderFx
})

sample({
    clock: approveByCustomerEv,
    target: approveByCustomerFx
})

sample({
    clock: approveByChamberEv,
    target: approveByChamberFx
})

sample({
    clock: rejectByProviderEv,
    target: rejectByProviderFx
})

sample({
    clock: rejectByCustomerEv,
    target: rejectByCustomerFx
})

sample({
    clock: rejectByChamberEv,
    target: rejectByChamberFx
})

sample({
    clock: requestCertEv,
    filter: (ev) => !!ev,
    target: requestCertFx
})

sample({
    clock: downloadFileEv,
    filter: (ev) => !!ev.id,
    target: downloadFileFx
})

sample({
    clock: downloadFilteredMatchesEv,
    target: downloadFilteredMatchesFx
})

approveByChamberFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Лот одобрен'}))
approveByProviderFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Участие в сделке одобрено'}))
approveByCustomerFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Участие в сделке одобрено'}))

rejectByChamberFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Лот отклонен'}))
rejectByProviderFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Участие в сделке отклонено'}))
rejectByCustomerFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Участие в сделке отклонено'}))

requestCertFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Заявка отправлена'}))

downloadFileEv.watch(ev => ev && notification.info({message: 'Загрузка началась...'}))