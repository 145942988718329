import {Button, Col, Popconfirm, Row, Table, Tooltip, Typography} from "antd";
import {useStore} from "effector-react";
import {$isAllowed} from "../../models/profileModel/index.js";
import {
    $matchesList,
    approveByCustomerEv,
    getMatchesListFx,
    MatchesListGate, rejectByProviderEv, rejectByCustomerEv, setApproveModalState, selectMatchEv
} from "../../models/matchesModel/index.js";
import {useEvent} from "effector-react/compat";
import StatusBadge from "../../components/StatusBadge/StatusBadge.jsx";
import {CheckOutlined, CloseOutlined, ExceptionOutlined, EyeOutlined} from "@ant-design/icons";
import {usePaginationControl} from "../../hooks/pagination-hook.js";
import FilterBlock from "../../components/FilterBlock/FilterBlock.jsx";
import {openViewModalEv} from "../../models/viewDetailsModalModel/index.js";
import ViewDetailsModal from "../ViewDetailsModal/ViewDetailsModal.jsx";
import MatchesTabs from "./components/MatchesTabs.jsx";
import ProviderApproveModal from "../../components/ProviderApproveModal/ProviderApproveModal.jsx";
import ChamberViewModal from "../../components/ProviderApproveModal/ChamberViewModal.jsx";


const Matches = () => {
    const perms = useStore($isAllowed)

    const openViewModal = useEvent(openViewModalEv)


    const loading = useStore(getMatchesListFx.pending)
    const {count, data} = useStore($matchesList)

    const [curPage, limit, onChangePagination, search] = usePaginationControl()

    // const approveSup = useEvent(approveByProviderEv)
    const openSupplierModal = useEvent(setApproveModalState)
    const approveBuy = useEvent(approveByCustomerEv)
    // const approveCham = useEvent(approveByChamberEv)
    const rejectSup = useEvent(rejectByProviderEv)
    const rejectBuy = useEvent(rejectByCustomerEv)
    // const rejectCham = useEvent(rejectByChamberEv)

    const viewProviderApprove = useEvent(selectMatchEv)

    const onApprove = (id, title) => {
        if (perms.supplier || perms.admin) {
            openSupplierModal({id, title, visible: true})
        } else if (perms.buyer) {
            approveBuy(id)
        }
    }

    const onReject = (id) => {
        if (perms.supplier) {
            rejectSup(id)
        } else if (perms.buyer) {
            rejectBuy(id)
        }
    }

    const showButtons = (match) => {
        if (!match.id) {
            return false
        } else if (perms.supplier && match.provider_approve !== null) {
            return false
        } else if (perms.buyer && match.customer_approve !== null) {
            return false
        } else if (perms.chamber) {
            return true
        } else return true
    }

    const columns = [
        {
            title: 'Статус',
            key: 'status',
            align: 'center',
            dataIndex: 'status',
            width: '5%',
            render: (status, record) => <StatusBadge
                status={status}
                cert={false}
                appProv={record?.provider_approve ?? null}
                appCust={record?.customer_approve ?? null}
            />
        },
        {
            title: 'Лот',
            key: 'lot_num',
            dataIndex: 'lot',
            render: (lot, record) => <div
                className={'link-like'}
                onClick={() => openViewModal({id: record.lot_id, type: 'lot'})}
            >
                {lot?.lot_number ?? record.lot_id ?? '-'}
            </div>
        },
        {
            title: 'Код ЕНС ТРУ',
            key: 'enstru',
            width: '10%',
            dataIndex: 'ens_tru',
            render: (ens) => ens ? ens : '-'
        },
        {
            title: 'Краткое описание',
            key: 'name',
            width: '20%',
            dataIndex: 'short_description',
            render: (desc) => desc ? <Typography.Paragraph>{desc}</Typography.Paragraph> : '-'
        },
        {
            title: 'Сертификат',
            key: 'cert',
            dataIndex: 'cert_id',
            render: (cert_id, record) => <div style={{display: 'flex', gap: 4}}>
                <div
                    className={'link-like'}
                    onClick={() => openViewModal({id: cert_id, type: 'cert'})}
                >
                    {record?.cert_gov_id ?? '-'}
                </div>
                <div style={{display: 'inline-block'}}>
                    {record?.cert_valid
                        ? <Tooltip title={'Действителен'}><CheckOutlined style={{fontSize: 16, color: '#553DB4'}}/></Tooltip>
                        : <Tooltip title={'Недействителен'}><CloseOutlined style={{fontSize: 16, color: '#FFB217'}}/></Tooltip>
                    }
                </div>
            </div>
        },
        {
            title: 'Поставщик',
            width: '20%',
            key: 'provider',
            render: (record) => <div style={{display: "flex", flexDirection: 'column', gap: 4}}>
                <div>{record?.provider_name_ru ?? record?.provider_name_kz ?? '-'}</div>
                <div>{record?.provider_name_kz ?? record?.provider_name_kz ?? '-'}</div>
                <div><span>БИН</span> {record?.provider_bin ?? '-'}</div>
            </div>,
        },
        {
            title: 'Закупщик',
            width: '20%',
            key: 'customer',
            render: (record) => <div style={{display: "flex", flexDirection: 'column', gap: 4}}>
                <div>{record?.customer_code ?? record.customer_code ?? '-'}</div>
                <div>{record?.customer_organization ?? '-'}</div>
            </div>,
        },
        {
            title: 'Импорто-\nзамещение',
            key: 'import',
            dataIndex: 'is_import_substitution',
            width: '5%',
            align: 'center',
            render: (imp) => imp ? <CheckOutlined /> : ''
        },
        {
            key: 'actions',
            width: perms.supplier ? '5%' : 0,
            align: 'center',
            render: (record) => showButtons(record) && !perms.chamber && !perms.gov ? <Button.Group style={{display: 'flex', gap: '1px', justifyContent: "center"}}>
                <Popconfirm
                    title={'Вы уверены, что хотите подтвердить участие в данном лоте?'}
                    onConfirm={() => onApprove(record.id, record.short_description)}
                    placement={'left'}
                    okText={'Да'}
                    cancelText={'Нет'}
                    okButtonProps={{className: 'btn-primary'}}
                    cancelButtonProps={{className: 'btn-secondary'}}
                >
                    <Tooltip
                        title={'Подтвердить'}
                        children={<Button className='btn-primary' icon={<CheckOutlined/>}/>}
                    />
                </Popconfirm>
                <Popconfirm
                    title={'Вы уверены, что хотите отказаться от участия в данном лоте?'}
                    onConfirm={() => onReject(record.id)}
                    placement={'left'}
                    okText={'Да'}
                    cancelText={'Нет'}
                    okButtonProps={{className: 'btn-primary'}}
                    cancelButtonProps={{className: 'btn-secondary'}}
                >
                    <Tooltip
                        title={'Отклонить'}
                        children={<Button className='btn-danger' icon={<CloseOutlined/>}/>}
                    />
                </Popconfirm>
                  <Tooltip
                    title={'Написать жалобу'}
                    children={<Button onClick={() => openViewModal({id: record.id, type: 'ticket'})} className='btn-danger' icon={<ExceptionOutlined />}/>}
                  />
            </Button.Group>
                : showButtons(record) && (perms.chamber || perms.gov)
                ? <Tooltip title={'Просмотр отклика Поставщика'}>
                        <Button className={'btn-primary'} icon={<EyeOutlined />}
                                onClick={() => viewProviderApprove(record.id)}
                        />
                </Tooltip>
                : 'Решение уже принято'
        }
    ]

    const filters = [
        {
            name: 'status', label: 'Статус', type: 'select', selectOptions: [
                {label: 'Новый', value: 'new'},
                {label: 'В процессе', value: 'in_progress'},
                {label: 'Отказано', value: 'rejected'},
                {label: 'Завершено', value: 'completed'}
            ]
        },
        {name: 'lot_name', label: 'Краткое описание', type: 'input'},
        {name: 'lot_ens_tru', label: 'Код ЕНС ТРУ', type: 'input'},
        {name: 'lot_year_of_purchase', label: 'Год закупки', type: 'date'},
        {name: 'cert_name', label: 'Описание товара', type: 'input'},
        {name: 'cert_manufacturer_name', label: 'Поставщик', type: 'input'},
        {name: 'cert_manufacturer_bin', label: 'БИН поставщика', type: 'input'},
        {name: 'cert_tn_ved', label: 'Код ТНВЭД', type: 'input'},
        {name: 'cert_kp_ved_code', label: 'Код КП ВЭД', type: 'input'},
        {name: 'cert_region', label: 'Регион', type: 'input'},
        {name: 'lot_completed', label: 'Завершен', type: 'switch'},
        {name: 'cert_is_valid', label: 'Серт. действителен', type: 'switch'}
    ]

    if (perms.buyer) {
        filters.splice(4, 0, {name: 'lot_customer_code', label: 'Код внутренней номенклатуры Заказчика', type: 'input'})
    }

    return <>
        {(perms.supplier || perms.admin) && <ProviderApproveModal/>}
        {(perms.chamber || perms.gov) && <ChamberViewModal/>}
        <ViewDetailsModal/>
        <MatchesListGate search={search.toString()}/>
        <Col span={24}>
            <Row><FilterBlock items={filters} /></Row>

            <Row style={{marginTop: 24}}><MatchesTabs /></Row>

            <Row>
                <Table columns={columns}
                       id='matches-table'
                       dataSource={data}
                       loading={loading}
                       style={{width: '100%'}}
                       bordered={true}
                       onChange={({current, pageSize}) => onChangePagination(current, pageSize)}
                       pagination={{
                           position: ['bottomCenter'],
                           pageSize: parseInt(limit),
                           current: parseInt(curPage),
                           total: count
                       }}
                />
            </Row>
        </Col>
    </>
}

export default Matches
