import {createGate} from "effector-react";
import {createEvent} from "effector";

export const MatchesListGate = createGate()
export const SelectedMatchGate = createGate()

export const approveByProviderEv = createEvent()

export const approveByCustomerEv = createEvent()

export const approveByChamberEv = createEvent()

export const rejectByProviderEv = createEvent()

export const rejectByCustomerEv = createEvent()

export const rejectByChamberEv = createEvent()

export const requestCertEv = createEvent()

export const setApproveModalState = createEvent()
export const resetApproveModal = createEvent()

export const selectMatchEv = createEvent()
export const resetMatchViewModalEv = createEvent()

export const downloadFileEv = createEvent()
export const downloadFilteredMatchesEv = createEvent()