import {Table} from "antd";
import {ListDicts} from "../../utils/ListTitlesDicts.js";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useMemo} from "react";

const formatValue = (v) => {
    if (v === true) {
        return <div style={{width: '85%'}}><CheckOutlined style={{fontSize: 24, color: '#553DB4'}}/></div>
    } else if (v === false) {
        return <div style={{width: '85%'}}><CloseOutlined style={{fontSize: 24, color: '#FFB217'}}/></div>
    } else if (v === 'local') {
        return 'Местные закупки'
    } else if (v === 'imported') {
        return 'Импортируемый'
    } else {
        return v?.toString() ?? '-'
    }
}

const TabList = ({data, dict, exclude}) => {
    const columns = [
        {dataIndex: 'key', width: '40%', align: 'end'}, {dataIndex: 'value'}
    ]

    const dataSource = useMemo(() => {
        return Object.entries(data)
            .filter(i => !exclude.includes(i[0]))
            .map(i => {
                return {key: ListDicts[dict][i[0]], value: formatValue(i[1])}
            })
    }, [data, dict])

    return <Table columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  showHeader={false}
                  bordered={true}
                  rowClassName={(_, idx) => idx % 2 === 0 ? 'table-odd-row' : ''}
    />
}

export default TabList