import {createEffect} from 'effector';
import {api} from '../../api/api.js';

export const getBlackListFx = createEffect()
    .use(async () => (await api().get('/providers-blacklist/')).data)

export const uploadBlackListFx = createEffect()
    .use(async (file) => {
        const config = {headers: {'Content-Type': 'multipart/form-data'}}
        const data = new FormData
        data.append('file', file.originFileObj)

        return (await api().post(`/providers-blacklist/upload`, data, config)).data
    })

export const addUserToBlacklistFx = createEffect()
    .use(async (gov_id) => (await api().post('/providers-blacklist/', {gov_id})).data)

export const excludeUserFromBlacklistFx = createEffect()
    .use(async (id) => (await api().delete(`/providers-blacklist/${id}`)).data)

export const gmkAddToWhitelistFx = createEffect()
    .use(async (blacklist_id) => (await api().post('/providers_whitelist/', {blacklist_id})).data)

export const gmkExcludeFromWhitelistFx = createEffect()
    .use(async (id) => (await api().delete(`/providers_whitelist/${id}`)).data)