import {Link} from 'react-router-dom';
import Toast from './Toast.jsx';
import {Tooltip} from 'antd';
import {useEvent} from 'effector-react';
import {markNotifAsReadEv} from '../../models/profileModel/index.js';

const NotificationLink = ({match_id}) => {
    const markRead = useEvent(markNotifAsReadEv)

    return <Tooltip title={'Перейти'} placement={'left'}>
        <Link to={`/view-statuses/match/${match_id}`} target={'_blank'}
              onClick={() => markRead(match_id)}
        >
            <Toast title={'Новое сообщение'} message={`В совпадении ${match_id}`}/>
        </Link>
    </Tooltip>
}

export default NotificationLink