import {combine, createStore} from "effector";

export const $profile = createStore({email: '', phone: '', role: ''})

export const $requestsStatusesCerts = createStore([])
export const $requestsStatusesMatches = createStore([])

export const $matchChatModalState = createStore({open: false, id: null})
export const $selectedMatchDets = createStore(null)

export const $selectedMatchProvider = createStore(null)

export const $sendMessageStatus = createStore(false)

export const $passChangeStatus = createStore(false)
export const $submitPotentialLotStatus = createStore(false)

export const $profileLoaded = combine($profile, u => u.role !== '')

export const $notifications = createStore([])

export const $isAllowed = combine($profile, u => (
    {
        supplier: u.role === 'provider',
        buyer: u.role === 'customer',
        chamber: u.role === 'chamber',
        admin: u.role === 'admin',
        gov: u.role === 'gov',
        investor: u.role === 'investor'
    }
))