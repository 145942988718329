import {$regReqStatus, $requestsList, $selectedRequestInfo, $selectedRequestModal} from "./stores";
import {
    RegRequestsGate,
    resetSelectedRequestEv,
    selectRequestEv,
    submitRegisterRequestEv,
    verifyRequestEv
} from "./events.js";
import {getRegRequestByIdFx, getRegRequestsListFx, submitRegisterRequestFx, verifyRequestFx} from "./effects";
import {sample} from "effector";
import notification from "antd/es/notification";

$regReqStatus.on(submitRegisterRequestFx.doneData, () => true)

$requestsList.on(getRegRequestsListFx.doneData, (_, payload) => payload)
    .reset(RegRequestsGate.close)

$selectedRequestModal.on(selectRequestEv, (state, payload) =>
    ({visible: true, id: payload})
)
    .reset(resetSelectedRequestEv)

$selectedRequestInfo.on(getRegRequestByIdFx.doneData, (_, payload) => payload)
    .reset(resetSelectedRequestEv)

sample({
    clock: [RegRequestsGate.open, verifyRequestFx.doneData],
    target: getRegRequestsListFx
})

sample({
    clock: $selectedRequestModal.updates,
    filter: (modal) => !!modal.id,
    fn: (modal) => modal.id,
    target: getRegRequestByIdFx
})

sample({
    clock: verifyRequestEv,
    target: verifyRequestFx
})

sample({
    clock: submitRegisterRequestEv,
    target: submitRegisterRequestFx
})

verifyRequestFx.doneData.watch(ev => ev && notification.success({message: 'Заявка одобрена'}))