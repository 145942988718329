import {createStore} from "effector";

export const $uploadedFile = createStore(null)

export const $filePreview = createStore({count: 0, data: []})
export const $filePreviewImport = createStore({count: 0, data: []})

export const $errorModalState = createStore({visible: false, data: []})

export const $gmkList = createStore([])

export const $selectedGmk = createStore(null)

export const $govPlanPreview = createStore([])

export const $govPlanPreviewImport = createStore([])