import {Button, Popconfirm, Table, Tooltip} from "antd";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import {useEvent, useStore} from "effector-react";
import AddPositionModal from "./AddPositionModal.jsx";
import {$importSubList, excludePosEv, setAddModalEv} from "../../../models/importSubstitutionModel/index.js";

const ManageImportSubList = () => {
    const importSubList = useStore($importSubList)

    const openAddModal = useEvent(setAddModalEv)
    const excludePos = useEvent(excludePosEv)

    const columns = [
        {
            title: 'ЕНС ТРУ',
            dataIndex: 'ens_tru',
        },
        {
            title: <Tooltip title={'Добавить позицию'}>
                <Button className={'btn-primary'} icon={<PlusOutlined />} onClick={() => openAddModal(true)}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            render: (record) => {
                return <Popconfirm title={'Вы уверены, что хотите исключить этого поставщика из черного списка?'}
                                placement={'left'}
                                onConfirm={() => excludePos(record.id)}
                                okText={'Да'}
                                cancelText={'Нет'}
                    >
                        <Tooltip title={'Исключить поставщика из черного списка'}>
                            <Button className={'btn-danger'} icon={<CloseOutlined />} />
                        </Tooltip>
                    </Popconfirm>
            }
        }
    ]

    return <>
        <AddPositionModal />
        <Table columns={columns} dataSource={importSubList} pagination={false}/>
    </>
}

export default ManageImportSubList