import {useEvent, useStore} from "effector-react";
import {Form, Input, Modal} from "antd";
import {$addPosModal, addPosEv, resetAddModalEv} from "../../../models/importSubstitutionModel/index.js";

const AddPositionModal = () => {
    const visible = useStore($addPosModal)
    const resetModal = useEvent(resetAddModalEv)
    const submitAdd = useEvent(addPosEv)

    const [importSubForm] = Form.useForm()

    return <Modal open={visible}
                  onCancel={() => {
                      resetModal()
                      importSubForm.resetFields()
                  }}
                  title={'Добавить позицию для импортозамещения'}
                  okText={'Добавить'}
                  onOk={() => importSubForm.submit()}
                  destroyOnClose={true}
    >
        <Form form={importSubForm} onFinish={(v) => submitAdd(v.ens_tru)} layout={'vertical'} validateTrigger={'onBlur'}>
            <Form.Item name={'ens_tru'} label={'Введите ЕНС ТРУ'}
                       rules={[{
                           pattern: /^\d{6}(?:\.\d{3})?(?:\.\d{6})?$/,
                           message: 'Код должен соответствовать формату 000000.000.000000'
                       }]}
            >
                <Input />
            </Form.Item>
        </Form>
    </Modal>
}

export default AddPositionModal