import {$viewModalState} from "./stores.js";
import {closeViewModalEv, openViewModalEv, sendTicketEv} from "./events.js";
import {sample} from "effector";
import {getCertFx} from "../certsModel/index.js";
import {getLotFx} from "../lotsModel/index.js";
import {sendTicketFx} from "./effects.js";

$viewModalState.on(openViewModalEv, (state, payload) => ({visible: true, ...payload}))
    .reset(closeViewModalEv)

sample({
    clock: $viewModalState.updates,
    filter: (modal) => modal.type === 'cert',
    fn: (modal) => modal.id,
    target: getCertFx
})

sample({
    clock: $viewModalState.updates,
    filter: (modal) => modal.type === 'lot',
    fn: (modal) => modal.id,
    target: getLotFx
})

sample({
    source: $viewModalState,
    clock: sendTicketEv,
    fn: (source, clock) => {
        return {
            ...clock,
            id: source.id
        }
    },
    target: sendTicketFx
})
