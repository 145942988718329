import {createEvent} from "effector";
import {createGate} from "effector-react";

export const RegisterGate = createGate()

export const loginEv = createEvent()
export const logoutEv = createEvent()

export const authorizeDSEv = createEvent()

export const completeRegEv = createEvent()

export const setAuthStatusEv = createEvent()