import Modal from "antd/es/modal";
import Card from "antd/es/card";
import {useStore, useEvent} from "effector-react";
import StepCard from "./components/StepCard.jsx";
import {
    $calculatedStages,
    $selectedContract, $statusOptions,
    $viewContractStepsModalState,
    getContractFx,
    resetContractStepsModalEv
} from "../../models/contractsModel/index.js";
import {$isAllowed} from "../../models/profileModel/index.js";

const ViewContractStepsModal = () => {
    const roles = useStore($isAllowed)
    const {visible} = useStore($viewContractStepsModalState)
    const loading = useStore(getContractFx.pending)
    const resetModal = useEvent(resetContractStepsModalEv)

    const contract = useStore($selectedContract)
    const statusOptions = useStore($statusOptions)

    const stages = useStore($calculatedStages)

    return <Modal title={`Статус контракта ${contract?.lot?.short_description ?? '-'}`}
           open={visible}
           width={'80vw'}
           onCancel={resetModal}
           destroyOnClose={true}
           footer={null}
           style={{top: 20}}
    >
        <Card style={{width: '100%', border: 'none', padding: 0, margin: 0, height: '85vh', overflow: 'auto'}} loading={loading}
              bodyStyle={{display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center'}}
        >
            {stages.map(i => <StepCard
                key={i?.stage_name}
                stage={i}
                options={statusOptions[i?.stage_name]}
                disabled={!roles.supplier || i?.disabled || i?.completed || !contract.is_active}
                contractId={contract.id}
            />)}
        </Card>
    </Modal>
}

export default ViewContractStepsModal