import {useMemo} from 'react';
import {useStore} from 'effector-react';
import {
    $profile,
    $selectedMatchDets
} from '../../../models/profileModel/index.js';
import {Card, Divider, Typography} from 'antd';
import styles from './Messages.module.css'
import SendMessageBlock from './SendMessageBlock.jsx';
import dayjs from 'dayjs';
import MessageFiles from './MessageFiles.jsx';

const getClass = (curId, msgId) => curId === msgId ? 'self' : 'other'
const getAuthor = (curId, authorId) => curId === authorId ? 'мной' : 'партнером'

const MessagesBlock = ({loading}) => {
    const match = useStore($selectedMatchDets)
    const {id: curUsrId} = useStore($profile)

    const displayed = useMemo(() => {
        return match?.comments?.map(m => {
            return <Card title={`Отправлено ${getAuthor(curUsrId, m?.created_by)}
                ${dayjs(m?.created_at)?.format('YYYY-MM-DD HH:mm')}
            `}
                         key={m?.id}
                         size={'small'}
                         className={styles[getClass(curUsrId, m?.created_by)]}
                         bodyStyle={{display: 'flex', flexDirection: 'column', gap: 4}}
                         headStyle={{color: 'rgba(0, 0, 0, 0.6', fontSize: 'small'}}
                         loading={loading}
            >
                <Typography.Paragraph>{m?.comment}</Typography.Paragraph>
                {m.files.length > 0 ? <MessageFiles files={m.files}/> : null}
            </Card>
        })
    }, [match])

    return <>
        <Card title={'Сообщения'} size={'small'} style={{marginTop: 16}}>
            <div className={styles.container}>
                {displayed?.length > 0 ? displayed : 'Сообщений пока нет...'}
            </div>
            <Divider/>
            <SendMessageBlock/>
        </Card>
    </>
}

export default MessagesBlock