import {Input, Modal} from "antd";
import {useEvent, useStore} from "effector-react";
import {$addUserModal, addUserEv, resetAddModalEv} from "../../../models/blacklistModel/index.js";
import {useState} from "react";

const AddUserToBlacklistModal = () => {
    const visible = useStore($addUserModal)
    const resetModal = useEvent(resetAddModalEv)
    const submitAdd = useEvent(addUserEv)

    const [value, setValue] = useState('')

    return <Modal open={visible}
                  onCancel={() => {
                      resetModal()
                      setValue('')
                  }}
                  title={'Добавить поставщика в черный список'}
                  okButtonProps={{disabled: !value}}
                  okText={'Добавить'}
                  onOk={() => submitAdd(value)}
                  destroyOnClose={true}
    >
        <p>Введите БИН</p>
        <Input value={value} onChange={(e) => setValue(e.target.value)}/>
    </Modal>
}

export default AddUserToBlacklistModal