import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Tooltip
} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useEvent, useStore} from 'effector-react';
import {
    exportResultEv, exportResultFx,
    getModelResultFx,
    submitModelFormEv
} from '../../models/analyticsModel/index.js';
import PrioritizationResultsModal from './PrioritizationResultsModal.jsx';

const LabelWithHelp = ({label, help}) => {
    return <Space>
        <span>{label}</span>
        <Tooltip title={help}>
            <QuestionCircleOutlined style={{color: '#553DB4'}}/>
        </Tooltip>
    </Space>
}

const PrioritizationModelForm = () => {
    const [form] = Form.useForm();
    const submitForm = useEvent(submitModelFormEv)
    const loading = useStore(getModelResultFx.pending)
    const exportResult = useEvent(exportResultEv)
    const loadingResult = useStore(exportResultFx.pending)

    const onFieldsChange = (curField, allFields) => {
        if (curField[0].name[0] === 'product') {
            return
        }

        const curNamePath = curField[0].name
        const curStage = curNamePath[0]
        const curStageFields = allFields.filter(i => i.name[0] === curStage)
        const curStageSum = curStageFields
            .reduce((acc, cur) => acc + (cur.value ?? 0), 0)

        if (curStageSum > 100) {
            form.setFields([{...curField[0], value: 0, errors: ['Сумма полей превышает 100%']}])
        } else if (curStageSum < 100 && curStageFields.some(i => i.errors.length > 0)) {
            form.setFields(curStageFields.map(i => ({...i, errors: []})))
        }
    }

    const totalModelsFields = Form.useWatch(['models'], form)
    const totalModelsValues = totalModelsFields
        ? Object?.values(totalModelsFields)?.reduce((acc, cur) => acc + (cur ?? 0), 0)
        : 0

    const model1Fields = Form.useWatch(['model1', 'gmk_import_volumes'], form)

    const totalModel2Fields = Form.useWatch(['model2'], form)
    const totalModel2Values = totalModel2Fields
        ? Object?.values(totalModel2Fields)?.reduce((acc, cur) => acc + (cur ?? 0), 0)
        : 0

    const totalModel3Fields = Form.useWatch(['model3'], form)
    const totalModel3Values = totalModel2Fields
        ? Object?.values(totalModel3Fields)?.reduce((acc, cur) => acc + (cur ?? 0), 0)
        : 0

    const valid = totalModelsValues === 100
        && model1Fields === 100
        && totalModel2Values === 100
        && totalModel3Values === 100

    return <>
        <PrioritizationResultsModal />
        <Card title={'Расчет модели приоритизации'}
              extra={
                  <Tooltip title={!valid
                      ? 'Каждая модель должна быть заполнена и сумма полей должна быть равна 100%'
                      : null}
                           placement={'bottom'}
                  >
                      <Button className={valid ? 'btn-primary' : null}
                              onClick={() => form.submit()}
                              loading={loadingResult}
                              disabled={!valid}
                      >
                          Рассчитать модель
                      </Button>
                  </Tooltip>
              }
        >
                <Form
                    form={form}
                    name={'prioritizationForm'}
                    layout={'vertical'}
                    onFinish={exportResult}
                    onFieldsChange={onFieldsChange}
                >
                    <Divider orientation={'left'} orientationMargin={0} style={{marginTop: 0}}>Товар</Divider>
                    <Row style={{gap: 48}}>
                        {/*<Col span={4}>*/}
                        {/*    <Form.Item name={['product', 'ens_tru']} label={'ЕНС ТРУ'} rules={[{required: true, message: 'Укажите код ЕНС ТРУ'}]}>*/}
                        {/*        <Input />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={4}>
                            <Form.Item name={['product', 'distance']} label={'Расстояние'} rules={[{required: true, message: 'Укажите расстояние'}]}>
                                <Select options={new Array(6).fill(0, 0, 6).map((i, idx) => ({label: idx, value: idx}))}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={4}>*/}
                        {/*    <Form.Item name={['product', 'tnved']} label={'ТНВЭД'} rules={[{required: true, message: 'Укажите код ТНВЭД'}]}>*/}
                        {/*        <Input />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={4}>*/}
                        {/*    <Form.Item name={['product', 'industry_68']} label={'Код индустрии 68'} rules={[{required: true, message: 'Укажите код'}]}>*/}
                        {/*        <Input />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={4}>*/}
                        {/*    <Form.Item name={['product', 'industry_20']} label={'Код индустрии 20'} rules={[{required: true, message: 'Укажите код'}]}>*/}
                        {/*        <Input />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                    <Space direction={'horizontal'} style={{marginBottom: 16}}>
                        <QuestionCircleOutlined style={{color: '#553DB4', fontSize: 16}}/>
                        <p>Обратите внимание, что сумма параметров каждой из моделей не должна
                            превышать 100%. При этом сумма параметров каждой модели должна равняться 100%
                        </p>
                    </Space>
                    <Divider orientation={'left'} orientationMargin={0}>
                        Веса моделей
                        {totalModelsValues > 0 && <span>{` (${totalModelsValues}%)`}</span>}
                    </Divider>
                    <Row justify={'space-between'}>
                        <Col span={4}>
                            <Form.Item name={['models', 'purchasing_weight']} label={'Данные закупок недропользователей'}>
                                <InputNumber min={0} max={100} addonAfter={'%'} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['models', 'industry_weight']} label={'Модель межотраслевого баланса'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['models', 'import_weight']} label={'Потенциал импортозамещения'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation={'left'} orientationMargin={0} style={{marginTop: 0}}>
                        Модель 1 - Данные закупок недропользователей
                    </Divider>
                    <Form.Item name={['model1', 'gmk_import_volumes']} label={'Объем импорта в закупках ГМК'}>
                        <InputNumber min={0} max={100} addonAfter={'%'} />
                    </Form.Item>
                    <Divider orientation={'left'} orientationMargin={0} style={{marginTop: 0}}>
                        Модель 2 - Модель межотраслевого баланса
                        {totalModel2Values > 0 && <span>{` (${totalModel2Values}%)`}</span>}
                    </Divider>
                    <Row style={{gap: 48}}>
                        <Col span={4}>
                            <Form.Item name={['model2', 'revenue']} label={'Мультипликатор выручки'}>
                                <InputNumber min={0} max={100} addonAfter={'%'} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model2', 'income']} label={'Мультипликатор дохода'}>
                                <InputNumber min={0} max={100} addonAfter={'%'} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model2', 'gva']} label={'Мультипликатор ВДС'}>
                                <InputNumber min={0} max={100} addonAfter={'%'} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model2', 'labor']} label={'Мультипликатор труда'}>
                                <InputNumber min={0} max={100} addonAfter={'%'} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model2', 'tax']} label={'Мультипликатор налогов'}>
                                <InputNumber min={0} max={100} addonAfter={'%'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation={'left'} orientationMargin={0} style={{marginTop: 0}}>
                        Модель 3 - Потенциал импортозамещения (индексы)
                        {totalModel3Values > 0 && <span>{` (${totalModel3Values}%)`}</span>}
                    </Divider>
                    <Row style={{gap: 48}}>
                        <Col span={4}>
                            <Form.Item name={['model3', 'total_import']} label={'Итоговый импорт'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'cagr_import']}
                                       label={<LabelWithHelp label={'СТР импорта'}
                                                             help={'Индекс Среднегодовых Темпов Роста импорта'}
                                       />}
                            >
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'volatility_import']} label={'Волатильность импорта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'price_import']} label={'Стоимость импорта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row style={{gap: 48}}>
                        <Col span={4}>
                            <Form.Item name={['model3', 'total_export']} label={'Итоговый экспорт'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'cagr_export']}
                                       label={<LabelWithHelp label={'СТР экспорта'}
                                                             help={'Индекс Среднегодовых Темпов Роста экспорта'}
                                       />}
                            >
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'volatility_export']} label={'Волатильность экспорта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'price_export']} label={'Стоимость экспорта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row style={{gap: 48}}>
                        <Col span={4}>
                            <Form.Item name={['model3', 'product_complexity']} label={'Сложность продукта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'product_distance']} label={'Дистанция продукта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'product_opportunity_gain']} label={'Потенциальная прибыль'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'product_rca']}
                                       label={<LabelWithHelp label={'RCA продукта'}
                                                             help={'Root Cause Analysis (Анализ причин) по продукту'}
                                       />}
                            >
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row style={{gap: 48}}>
                        <Col span={4}>
                            <Form.Item name={['model3', 'total_global_import']} label={'Глобальный импорт'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'cagr_global_import']} label={'Глобальный СТР импорта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={['model3', 'volatility_global_import']} label={'Глобальная волатильность импорта'}>
                                <InputNumber min={0} max={100} addonAfter={'%'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
}

export default PrioritizationModelForm