import {createEffect} from "effector";
import {api} from "../../api/api.js";

export const getLotsListFx = createEffect()
    .use(async (search) => (await api().get(`/lots/${search.length > 0 ? `?${search}` : ''}`)).data)

export const getLotFx = createEffect()
    .use(async (id) => (await api().get(`/lots/${id}`)).data)

export const closeLotFx = createEffect()
    .use(async (lot_id) => (await api().post(`/lots/complete/${lot_id}`)))

export const deleteLotsFx = createEffect()
    .use(async (ids) => (await api().delete(`/lots/`, {data: {ids}})).data)