import {$lotsList, $selectedLot} from "./stores.js";
import {closeLotFx, deleteLotsFx, getLotFx, getLotsListFx} from './effects.js';
import {closeLotEv, deleteLotsEv, LotsListGate, SelectedLotGate} from './events.js';
import {sample} from "effector";
import notification from "antd/es/notification";

$lotsList.on(getLotsListFx.doneData, (state, payload) => payload)
    .reset(LotsListGate.close)

$selectedLot.on(getLotFx.doneData, (state, payload) => payload)
    .reset(SelectedLotGate.close)

sample({
    clock: closeLotEv,
    filter: (ev) => !!ev,
    target: closeLotFx,
})

sample({
    source: LotsListGate.state,
    clock: closeLotFx.doneData,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getLotsListFx
})

sample({
    source: LotsListGate.state,
    clock: deleteLotsFx.doneData,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getLotsListFx
})

sample({
    clock: LotsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getLotsListFx
})

sample({
    clock: SelectedLotGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => gate.id,
    target: getLotFx
})

sample({
    clock: deleteLotsEv,
    target: deleteLotsFx
})

deleteLotsFx.fail.watch((data) => {
    notification.error({
        message: 'Ошибка',
        description: `Следующие лоты уже имеют подтверждение: ${data.error.response.data.message}`
    })
})

closeLotFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Лот закрыт'}))