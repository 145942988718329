import {$analyticsGateStatusSt, $analyticsSt, AnalyticsGate} from "../../models/analyticsModel/index.js";
import {useStore} from "effector-react";
import {models} from "powerbi-client";
import {PowerBIEmbed} from "powerbi-client-react";
import {Spin} from "antd";
import './analytic.css'

const Analytics = () => {

    const status = useStore($analyticsGateStatusSt)

    const GateAnalytics = AnalyticsGate;

    const analytics = useStore($analyticsSt)

    console.log('GateAnalytics', GateAnalytics)

    // let prepareEmbedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`
    let prepareEmbedUrl = `https://app.powerbi.com/reportEmbed?reportId=${analytics?.reportId}`
    console.log('prepareEmbedUrl', prepareEmbedUrl)

    return <>
        <Spin spinning={!status}>

        {/*<iframe className="main_frame external"*/}
        {/*        src={src}*/}
        {/*        frameBorder="0" allowFullScreen="true"*/}
        {/*        style={{border: 'none', height: '85vh', width: '100%'}}*/}

        {/*>*/}

        {/*</iframe>*/}

            <GateAnalytics/>

            <PowerBIEmbed

                embedConfig={
                    {
                        type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                        id: analytics?.reportId,
                        embedUrl: prepareEmbedUrl,
                        accessToken: analytics?.token,
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                        }
                    }
                }

                eventHandlers={
                    new Map([
                        ['loaded', function () {
                            console.log('Report loaded');
                        }],
                        ['rendered', function () {
                            console.log('Report rendered');
                        }],
                        ['error', function (event) {
                            console.log(event.detail);
                        }]
                    ])
                }

                cssClassName={
                    "report-style-class"
                }

                getEmbeddedComponent={
                    (embeddedReport) => {
                        window.report = embeddedReport;
                    }
                }
            />
        </Spin>
    </>

}

export default Analytics
