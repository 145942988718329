import {$authStatus, $regSuccess, $signedCert} from "./stores.js";
import {authorizeDSFx, completeRegFx, loginFx, logoutFx} from "./effects.js";
import {sample} from "effector";
import {
    authorizeDSEv,
    completeRegEv,
    loginEv,
    logoutEv,
    RegisterGate,
    setAuthStatusEv
} from "./events.js";
import {cookies} from "../../api/api.js";
import notification from "antd/es/notification";

$authStatus.on(loginFx.doneData, () => true)
    .on(setAuthStatusEv, (state, payload) => payload)

$signedCert.on(authorizeDSFx.doneData, (_, payload) => payload.replace('/[\\n\\r]/gm', '').split('-----')[2].trim())
    .reset(RegisterGate.close)

$regSuccess.on(completeRegFx.doneData, () => true)
    .reset(RegisterGate.close)

authorizeDSFx.doneData.watch(ev => ev && notification.success({
    message: 'Успешно',
    description: 'Авторизация ЭЦП завершена'
}))

completeRegFx.doneData.watch(ev => ev && notification.success({
    message: 'Успешно',
    description: 'Регистрация завершена. Авторизуйтесь с помощью вашего БИН и пароля, указанного при регистрации.',
    duration: 5
}))

sample({
    clock: loginEv,
    target: loginFx
})

loginFx.doneData.watch((data) => cookies.set('access_token', data.access_token))

sample({
    clock: logoutEv,
    target: logoutFx
})

logoutFx.doneData.watch(() => cookies.remove('access_token'))

sample({
    clock: logoutFx.doneData,
    fn: () => false,
    target: setAuthStatusEv
})

sample({
    clock: authorizeDSEv,
    target: authorizeDSFx
})

sample({
    clock: completeRegEv,
    target: completeRegFx
})