import {createEvent} from "effector";
import {createGate} from 'effector-react';

export const previewPlanEv = createEvent()
export const previewPlanImportEv = createEvent()

export const cancelPlanEv = createEvent()

export const confirmPlanEv = createEvent()
export const confirmPlanImportEv = createEvent()

export const setUploadErrorModalEv = createEvent()
export const resetUploadErrorModalEv = createEvent()


export const GovCustomersGate = createGate()

export const selectGmkEv = createEvent()

export const govApprovePlanEv = createEvent()
export const govApprovePlanImportEv = createEvent()