import {Space, Tooltip, Typography} from 'antd';
import {useState} from 'react';
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';

const ControlledEllipsis = ({text}) => {
    const [expanded, setExpanded] = useState(false)
    return <Tooltip title={expanded ? 'Свернуть' : 'Развернуть'}>
        <Space direction={'vertical'} size={'small'}>
            <Typography.Paragraph
                style={{cursor: 'pointer', marginBottom: 0}}
                onClick={() => setExpanded(!expanded)}
                ellipsis={expanded
                    ? false
                    : {
                        rows: 2,
                        expandable: false,
                    }}
            >
                {text}
            </Typography.Paragraph>
            {expanded ? <CaretUpOutlined style={{color: '#553DB4'}} onClick={() => setExpanded(false)}/> :
                <CaretDownOutlined style={{color: '#553DB4'}} onClick={() => setExpanded(true)}/>}
        </Space>
    </Tooltip>
}

export default ControlledEllipsis