import {Button, Card, Form, Input} from "antd";
import {useEvent, useStore} from "effector-react";
import {$regReqStatus, submitRegisterRequestEv, submitRegisterRequestFx} from "../../models/regRequestsModel/index.js";
import {CheckCircleOutlined} from "@ant-design/icons";

const RegisterRequest = () => {
    const loading = useStore(submitRegisterRequestFx.pending)
    const reqStatus = useStore($regReqStatus)

    const [regForm] = Form.useForm()

    // const validPass = new RegExp('^(?=.*[a-z])^(?=.*[A-Z])^(?=.*\\d)^(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,32}$')

    const onSubmit = useEvent(submitRegisterRequestEv)

    const onFinish = (values => onSubmit(values))

    return <Card title={'Заявка на регистрацию'} className={'login-form'} style={{width: '60vw'}} loading={loading}
                 extra={reqStatus ? <CheckCircleOutlined style={{color: 'green', fontSize: 28}}/> : null}
    >
        {reqStatus
            ? <p>Заявка на регистрацию подана, ожидайте одобрения.</p>
            : <>
                <Form name={'regForm'} form={regForm} layout={'vertical'} onFinish={onFinish}
                      validateTrigger={'onBlur'}>
                    <Form.Item name={'full_name'} label={'ФИО'}
                               rules={[{required: true, message: 'Укажите ФИО'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'email'} label={'Электронная почта'}
                               rules={[{required: true, message: 'Укажите действительный email', type: 'email'}]}
                    >
                        <Input type={'email'}/>
                    </Form.Item>
                    <Form.Item name={'phone_number'} label={'Телефон'}
                               rules={[{
                                   required: true,
                                   message: 'Укажите действительный номер телефона',
                                   pattern: /(\+[0-9]{7}|[0-9]{7})/
                               }]}
                    >
                        <Input type={'tel'}/>
                    </Form.Item>
                    <Form.Item name={'organization'} label={'Организация'}
                               rules={[{required: true, message: 'Введите название Вашей организации'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'division'} label={'Отдел'}
                               rules={[{required: true, message: 'Введите название Вашего отдела'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'job_title'} label={'Должность'}
                               rules={[{required: true, message: 'Введите вашу должность'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'head'} label={'Глава компании'}
                               rules={[{required: true, message: 'Введите ФИО главы компании'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'purpose_of_use'} label={'Цель регистрации'}
                               rules={[{required: true, message: 'Укажите цель регистрации'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'message'} label={'Комментарий'}>
                        <Input/>
                    </Form.Item>
                    {/*<Form.Item name={'password'} label={'Придумайте пароль'}*/}
                    {/*           rules={[*/}
                    {/*               {required: true, message: 'Введите пароль'},*/}
                    {/*               {*/}
                    {/*                   pattern: validPass,*/}
                    {/*                   message: 'Пароль должен содержать символы a-z, A-Z, 0-9, _!@#$%^&?*, длина 8-32'*/}
                    {/*               },*/}
                    {/*           ]}*/}
                    {/*>*/}
                    {/*    <Input.Password/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item name={'confirm_password'} label={'Подтвердите пароль'}*/}
                    {/*           dependencies={['password']}*/}
                    {/*           rules={[*/}
                    {/*               {required: true, message: 'Подтвердите пароль'},*/}
                    {/*               ({getFieldValue}) => ({*/}
                    {/*                   validator(_, value) {*/}
                    {/*                       if (getFieldValue('password') === value) {*/}
                    {/*                           return Promise.resolve();*/}
                    {/*                       } else {*/}
                    {/*                           return Promise.reject(new Error('Пароли не совпадают'));*/}
                    {/*                       }*/}
                    {/*                   },*/}
                    {/*               }),*/}
                    {/*           ]}*/}
                    {/*>*/}
                    {/*    <Input.Password/>*/}
                    {/*</Form.Item>*/}
                </Form>
                <Button className={'btn-primary'} onClick={() => regForm.submit()}>
                    Отправить заявку
                </Button>
            </>
        }
    </Card>
}

export default RegisterRequest