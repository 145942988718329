import {createGate} from "effector-react";
import {createEvent} from "effector";

export const ContractsListGate = createGate()

export const getContractEv = createEvent()

export const setContractStepsModalEv = createEvent()
export const resetContractStepsModalEv = createEvent()

export const createStatusEv = createEvent()
export const updateStatusEv = createEvent()

export const approveByProviderEv = createEvent()
export const approveByCustomerEv = createEvent()


export const rejectByProviderEv = createEvent()
export const rejectByCustomerEv = createEvent()