import {createEffect} from "effector";
import {api} from "../../api/api.js";
import queryString from "query-string";

export const getMatchesListFx = createEffect()
    .use(async (search) => (await api().get(`/matches/${search.length > 0 ? `?${search}` : ''}`)).data)

export const getMatchFx = createEffect()
    .use(async (id) => (await api().get(`/matches/${id}`)).data)

export const approveByProviderFx = createEffect()
    .use(async ({id, values}) => {
        const formData = new FormData()
        for (const [field, value] of Object.entries(values)) {
            if (field === 'files' && value?.length > 0) {
                value.forEach(file => formData.append('files', file, file.name))
            } else if (value !== undefined) {
                formData.append(field, value)
            } else {
                formData.append(field, '')
            }
        }
        return (await api().post(`/matches/provider-approve/${id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data
    })

export const approveByCustomerFx = createEffect()
    .use(async (id) => (await api().post(`/matches/customer-approve/${id}`)).data)

export const approveByChamberFx = createEffect()
    .use(async (id) => (await api().post(`/matches/chamber-approve/${id}`)).data)

export const requestCertFx = createEffect()
    .use(async (lot_id) => (await api().post('/cert-requests/', {lot_id})).data)

export const rejectByProviderFx = createEffect()
    .use(async (id) => (await api().post(`/matches/provider-reject/${id}`)).data)

export const rejectByCustomerFx = createEffect()
    .use(async (id) => (await api().post(`/matches/customer-reject/${id}`)).data)

export const rejectByChamberFx = createEffect()
    .use(async (id) => (await api().post(`/matches/chamber-reject/${id}`)).data)

export const downloadFileFx = createEffect()
    .use(async ({id, name}) => {
        const file = await api().get(`/match-files/download/${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = name
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const downloadFilteredMatchesFx = createEffect()
    .use(async (payload) => {
        const query = queryString.stringify(payload)

        const file = await api().get(`/matches/download?${query}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = name
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })