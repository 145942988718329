import {
    $isAllowed,
    $matchChatModalState, $notifications,
    $passChangeStatus,
    $profile, $profileLoaded,
    $requestsStatusesCerts,
    $requestsStatusesMatches, $selectedMatchDets, $selectedMatchProvider, $sendMessageStatus,
    $submitPotentialLotStatus
} from './stores.js';
import {
    changePasswordFx, deleteImportedLotsFx, deleteLocalLotsFx, downloadMatchFileFx, getMatchDetsFx,
    getMatchesStatusesFx, getNotificationsFx,
    getProfileFx, getProviderInfoFx,
    getReqStatusesFx, sendMatchMessageFx,
    submitEditFx,
    submitPotentialLotFx
} from './effects.js';
import {
    AppGate,
    changePasswordEv,
    deleteImportedLotsEv,
    deleteLocalLotsEv,
    downloadMatchCommentFileEv,
    markNotifAsReadEv,
    ReqStatusesGate,
    resetMatchModalEv,
    resetSubmitStatusEv,
    sendMatchMessageEv,
    setMatchModalState,
    submitEditEv,
    submitPotentialLotEv
} from './events.js';
import {sample} from "effector";
import {notification} from "antd";
import {approveByCustomerFx} from "../matchesModel/index.js";

$profile.on(getProfileFx.doneData, (state, payload) => payload)

$requestsStatusesCerts.on(getReqStatusesFx.doneData, (state, payload) => payload)
    .reset(ReqStatusesGate.close)

$requestsStatusesMatches.on(getMatchesStatusesFx.doneData, (state, payload) => payload.data)
    .reset(ReqStatusesGate.close)

$passChangeStatus.on(changePasswordFx.doneData, () => true)

$submitPotentialLotStatus.on(submitPotentialLotFx.doneData, () => true)
    .reset(resetSubmitStatusEv)

$matchChatModalState.on(setMatchModalState, (state, payload) => payload)
    .reset(resetMatchModalEv)

$selectedMatchDets.on(getMatchDetsFx.doneData, (state, payload) => payload)
    .reset(resetMatchModalEv)

$selectedMatchProvider.on(getProviderInfoFx.doneData, (state, payload) => payload)
    .reset(resetMatchModalEv)

$sendMessageStatus.on(sendMatchMessageFx.doneData, () => true)
    .reset([resetMatchModalEv, sendMatchMessageEv])

$notifications.on(getNotificationsFx.doneData, (state, payload) => payload.data)
    .on(markNotifAsReadEv, (state, payload) => state
        ?.filter(i => i.match_id !== payload)
    )
    .reset(AppGate.close)

sample({
    clock: [AppGate.open, submitEditFx.doneData, submitPotentialLotFx.doneData, deleteImportedLotsFx.doneData, deleteLocalLotsFx.doneData],
    target: getProfileFx
})

sample({
    clock: ReqStatusesGate.open,
    target: getReqStatusesFx
})

sample({
    clock: [ReqStatusesGate.open, approveByCustomerFx.doneData],
    fn: () => `in_process=true`,
    target: getMatchesStatusesFx
})

sample({
    clock: submitEditEv,
    target: submitEditFx
})

sample({
    clock: changePasswordEv,
    target: changePasswordFx
})

sample({
    source: $profile,
    clock: submitPotentialLotEv,
    filter: (_, newEns) => newEns.length > 0,
    fn: (profile, newEns) => {
        const {phone_number, email, ens_tru} = profile
        return {phone_number, email, ens_tru: ens_tru ? [...ens_tru, ...newEns] : newEns}
    },
    target: submitPotentialLotFx
})

sample({
    clock: $matchChatModalState.updates,
    filter: modal => !!modal.open && !!modal.id,
    fn: modal => modal.id,
    target: getMatchDetsFx
})

sample({
    source: $matchChatModalState.map(state => state.id),
    clock: sendMatchMessageFx.doneData,
    filter: (id) => !!id,
    fn: (id) => id,
    target: getMatchDetsFx
})

sample({
    source: $isAllowed,
    clock: getMatchDetsFx.doneData,
    filter: (perms, match) => perms.buyer && !!match?.provider?.gov_id,
    fn: (_, match) => match.provider.gov_id,
    target: getProviderInfoFx
})

sample({
    source: $matchChatModalState.map(state => state.id),
    clock: sendMatchMessageEv,
    filter: (id) => !!id,
    fn: (id, payload) => ({id, payload}),
    target: sendMatchMessageFx
})

sample({
    clock: downloadMatchCommentFileEv,
    target: downloadMatchFileFx
})

sample({
    source: AppGate.status,
    clock: $isAllowed.updates,
    filter: (gate, perms) => (perms.buyer || perms.supplier) && gate,
    target: getNotificationsFx
})

sample({
    clock: deleteLocalLotsEv,
    target: deleteLocalLotsFx
})

sample({
    clock: deleteImportedLotsEv,
    target: deleteImportedLotsFx
})

changePasswordFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Пароль изменен'}))