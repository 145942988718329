import {sample} from "effector";
import {
    cancelPlanEv,
    confirmPlanEv,
    resetUploadErrorModalEv,
    setUploadErrorModalEv,
    previewPlanImportEv,
    previewPlanEv,
    confirmPlanImportEv,
    selectGmkEv,
    GovCustomersGate,
    govApprovePlanEv,
    govApprovePlanImportEv
} from './events.js';
import {
    confirmPlanFx,
    previewPlanFx,
    previewPlanImportFx,
    confirmPlanImportFx,
    govGetGmkListFx, govGetPlanPerGmkFx, govApprovePlanFx, govApprovePlanImportFx
} from './effects.js';
import {
    $errorModalState,
    $filePreview,
    $filePreviewImport, $gmkList, $govPlanPreview, $govPlanPreviewImport,
    $selectedGmk,
    $uploadedFile
} from './stores.js';
import {notification} from "antd";
import {getProfileFx} from "../profileModel/index.js";

$filePreview.reset([confirmPlanFx.doneData, cancelPlanEv])

$filePreviewImport.reset([confirmPlanImportFx, cancelPlanEv])

$errorModalState.on(setUploadErrorModalEv, (state, payload) => payload)
    .reset(resetUploadErrorModalEv)

$uploadedFile.on([previewPlanEv, previewPlanImportEv], (_, payload) => payload)

$selectedGmk.reset(GovCustomersGate.close)

$gmkList.on(govGetGmkListFx.doneData, (_, payload) => payload)
    .reset(GovCustomersGate.close)

$govPlanPreview.on(govGetPlanPerGmkFx.doneData, (_, payload) => payload.data
    .filter(i => i.lot_type === 'local')
)
    .reset([GovCustomersGate.close, govApprovePlanFx.doneData])

$govPlanPreviewImport.on(govGetPlanPerGmkFx.doneData, (_, payload) => payload.data
    .filter(i => i.lot_type === 'imported')
)
    .reset([GovCustomersGate.close, govApprovePlanImportFx.doneData])

sample({
    clock: previewPlanEv,
    target: previewPlanFx
})

sample({
    clock: previewPlanImportEv,
    target: previewPlanImportFx
})

sample({
    source: $uploadedFile,
    clock: confirmPlanEv,
    fn: (file) => file,
    target: confirmPlanFx
})

sample({
    source: $uploadedFile,
    clock: confirmPlanImportEv,
    fn: (file) => file,
    target: confirmPlanImportFx
})

sample({
    clock: previewPlanFx.doneData,
    filter: (payload) => payload.status !== 'error',
    fn: (payload) => payload.data,
    target: $filePreview
})

sample({
    clock: previewPlanImportFx.doneData,
    filter: (payload) => payload.status !== 'error',
    fn: (payload) => payload.data,
    target: $filePreviewImport
})


sample({
    clock: [previewPlanFx.doneData, previewPlanImportFx.doneData],
    filter: (ev) => ev.status === 'error',
    fn: (ev) => (
        {
            visible: true,
            data: Object.entries(ev?.message)?.map(i => ({row: i[0], col: i[1]?.join(', ') ?? ''})) ?? []
        }
    ),
    target: setUploadErrorModalEv
})


sample({
    clock: [confirmPlanFx.doneData, confirmPlanImportFx.doneData],
    target: getProfileFx
})

sample({
    clock: GovCustomersGate.open,
    target: govGetGmkListFx
})

sample({
    source: $gmkList,
    clock: selectGmkEv,
    fn: (list, id) => list.find(i => i.id === id),
    target: $selectedGmk
})

sample({
    clock: $selectedGmk.updates,
    fn: (gmk) => gmk.id,
    target: govGetPlanPerGmkFx
})

sample({
    source: $selectedGmk,
    clock: govApprovePlanEv,
    filter: (gmk) => !!gmk.id,
    fn: (gmk) => gmk.id,
    target: govApprovePlanFx
})

sample({
    source: $selectedGmk,
    clock: govApprovePlanImportEv,
    filter: (gmk) => !!gmk.id,
    fn: (gmk) => gmk.id,
    target: govApprovePlanImportFx
})

previewPlanFx.failData.watch(ev => ev && notification.error({
    message: 'Ошибка',
    description: 'Убедитесь, что Вы выбрали табличный файл формата Excel'
}))

previewPlanImportFx.failData.watch(ev => ev && notification.error({
    message: 'Ошибка',
    description: 'Убедитесь, что Вы выбрали табличный файл формата Excel'
}))

confirmPlanFx.doneData.watch(ev => ev && notification.success({
    message: 'Успешно',
    description: 'План загружен и подтвержден'
}))

confirmPlanImportFx.doneData.watch(ev => ev && notification.success({
    message: 'Успешно',
    description: 'План на 3 года загружен и подтвержден'
}))

cancelPlanEv.watch(ev => ev && notification.success({
    message: 'Успешно',
    description: 'Вы можете загрузить новый план'
}))

govApprovePlanFx.doneData.watch(() => {
    notification.success({
        message: 'Успешно',
        description: 'План местных закупок подтвержден'
    })
})

govApprovePlanImportFx.doneData.watch(() => notification.success({
    message: 'Успешно',
    description: 'Импортный план подтвержден'
}))