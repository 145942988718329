import {Button, Card, Form, Popconfirm, Row} from 'antd';
import Dragger from "antd/es/upload/Dragger";
import {UploadOutlined} from "@ant-design/icons";
import {useStore, useUnit} from 'effector-react';
import {
    $filePreview, $filePreviewImport,
    cancelPlanEv, confirmPlanImportEv,
    confirmPlanEv, previewPlanImportEv, previewPlanImportFx, previewPlanEv, previewPlanFx,
} from "../../models/uploadModel/index.js";
import {useEvent} from "effector-react/compat";
import {
    $profile,
    deleteImportedLotsEv,
    deleteLocalLotsEv
} from '../../models/profileModel/index.js';
import UploadPlanErrorModal from "../../components/UploadPlanErrorModal/UploadPlanErrorModal.jsx";
import PreviewPlan from "./components/PreviewPlan.jsx";

const UploadPlan = () => {
    const upload = useEvent(previewPlanEv)
    const uploadImport = useEvent(previewPlanImportEv)

    const confirmPlan = useEvent(confirmPlanEv)
    const confirmPlanImport = useEvent(confirmPlanImportEv)
    const cancelPlan = useEvent(cancelPlanEv)

    const uploadLoading = useStore(previewPlanFx.pending)
    const uploadImportLoading = useStore(previewPlanImportFx.pending)

    const deleteLocal = useUnit(deleteLocalLotsEv)
    const deleteImported = useUnit(deleteImportedLotsEv)

    const preview = useStore($filePreview)
    const previewImport = useStore($filePreviewImport)

    const {have_local_lots, have_imported_lots} = useStore($profile)

    const [planForm] = Form.useForm()
    const files = Form.useWatch('file', planForm)

    const [planImportForm] = Form.useForm()
    const filesImport = Form.useWatch('fileImport', planImportForm)

    const onCancelLocal = () => {
        planForm.resetFields()
        cancelPlan()
    }

    const onCancelImport = () => {
        planImportForm.resetFields()
        cancelPlan()
    }

    const downloadFormat = () => {
        const fileUrl = __PUBLIC_URL__ + '/plan_format.xlsx';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'plan_format.xlsx');
        document.body.appendChild(link);
        link.click();
    }

    return <>
        <UploadPlanErrorModal />
        <Button onClick={downloadFormat} type={'primary'} style={{marginBottom: 24}}>
            Скачать шаблон плана
        </Button>
        <Card title={'Загрузить план местных закупок'}>
            {have_local_lots && <Popconfirm title={'Вы уверены, что хотите удалить план местных закупок?'} onConfirm={() => deleteLocal()} okText={'Да'} okButtonProps={{danger: true}}>
                <Button danger>Удалить план местных закупок</Button>
            </Popconfirm>}
            {have_local_lots
                ? <p>План местных закупок уже загружен и подтвержден</p>
                : preview.length > 0
                ? <p>План загружен, подтвердите или отмените его в окне предпросмотра ниже</p>
                : <><Form form={planForm}
                          onFinish={(v) => upload(v?.file?.fileList[0])}
                          layout={'vertical'}
                >
                    <Form.Item name={'file'} valuePropName={'file'}>
                        <Dragger
                            name={'file'}
                            disabled={have_local_lots}
                            maxCount={1}
                            multiple={false}
                            beforeUpload={() => false}
                            style={{width: '40%'}}
                        >
                            <UploadOutlined style={{fontSize: 48}}/>
                            <p>Кликните чтобы выбрать файл или перетащите файл в данное поле</p>
                        </Dragger>
                    </Form.Item>
                </Form>
                    <Row style={{gap: 8}}>
                        <Button type={'primary'}
                                className={(!files?.fileList || files?.fileList?.length < 1) ? '' : 'btn-primary'}
                                onClick={() => planForm.submit()}
                                disabled={!files?.fileList || files?.fileList?.length < 1}
                                loading={uploadLoading}
                        >
                            Загрузить
                        </Button>
                    </Row>
                </>}
        </Card>

        {
            preview.length > 0 && <PreviewPlan cancelPlan={onCancelLocal} confirmPlan={confirmPlan} data={preview}/>
        }

        <Card title={'Загрузить план импорта'} style={{marginTop: 24}}>
            {have_imported_lots && <Popconfirm title={'Вы уверены, что хотите удалить план импортируемых закупок?'} onConfirm={() => deleteImported()} okText={'Да'} okButtonProps={{danger: true}}>
                <Button danger>Удалить план импортируемых закупок</Button>
            </Popconfirm>}
            {have_imported_lots
                    ? <p>План импорта уже загружен</p>
                    : previewImport.length > 0
                        ? <p>План загружен, подтвердите или отмените его в окне предпросмотра ниже</p>
                        : <>
                            <Form form={planImportForm}
                                  onFinish={(v) => uploadImport(v?.fileImport?.fileList[0])}
                                  layout={'vertical'}
                            >
                                <Form.Item name={'fileImport'} valuePropName={'fileImport'}>
                                    <Dragger
                                        name={'fileImport'}
                                        disabled={have_imported_lots}
                                        maxCount={1}
                                        multiple={false}
                                        beforeUpload={() => false}
                                        style={{width: '40%'}}
                                    >
                                        <UploadOutlined style={{fontSize: 48}}/>
                                        <p>Кликните чтобы выбрать файл или перетащите файл в данное поле</p>
                                    </Dragger>
                                </Form.Item>
                            </Form>
                            <Row style={{gap: 8}}>
                                <Button type={'primary'}
                                        className={(!filesImport?.fileList || filesImport?.fileList?.length < 1) ? '' : 'btn-primary'}
                                        onClick={() => planImportForm.submit()}
                                        disabled={!filesImport?.fileList || filesImport?.fileList?.length < 1}
                                        loading={uploadImportLoading}
                                >
                                    Загрузить
                                </Button>
                            </Row>
                        </>}
        </Card>
        {
        previewImport.length > 0 && <PreviewPlan cancelPlan={onCancelImport} confirmPlan={confirmPlanImport} data={previewImport}/>
        }
    </>
}

export default UploadPlan