import {
    exportResultFx,
    getBiAccessTokenFx, getModelResultFx,
} from './effects.js';
import {
    AnalyticsGate, exportResultEv, resetModelModalEv, selectResultEv, submitModelFormEv,
} from './events.js';
import {forward, sample} from 'effector';
// import notification from "antd/es/notification";
import {
    $analyticsGateStatusSt,
    $analyticsSt,
    $modelModalState,
    $modelResult,
    $selectedResult
} from './stores.js';


$analyticsGateStatusSt.on(AnalyticsGate.open, () => true)

$analyticsSt.on(getBiAccessTokenFx.doneData, (_, payload) => (payload))

$modelResult.on(getModelResultFx.doneData, (_, payload) => payload)
    .reset(resetModelModalEv)

$modelModalState.on(getModelResultFx.doneData, () => ({open: true}))
    .reset(resetModelModalEv)

$selectedResult.reset(resetModelModalEv)


forward({
    from: AnalyticsGate.open,
    to: getBiAccessTokenFx
})

sample({
    clock: submitModelFormEv,
    fn: (values) => {
        const {product: query} = values
        for (const [key, value] of Object.entries(query)) {
            if (value === undefined || value === null) {
                delete query[key]
            }
        }

        delete values.ens_tru
        const payload = Object.fromEntries(Object.values(values).flatMap(i => Object.entries(i)))
        for (const [key, value] of Object.entries(payload)) {
            if (value === undefined || value === null) {
                payload[key] = 0
            }
        }
        return {query, payload}
    },
    target: getModelResultFx
})

sample({
    source: $modelResult,
    clock: selectResultEv,
    fn: (results, idx) => results[idx],
    target: $selectedResult
})

sample({
    clock: exportResultEv,
    fn: (values) => {
        const {product: query} = values
        for (const [key, value] of Object.entries(query)) {
            if (value === undefined || value === null) {
                delete query[key]
            }
        }

        delete values.product
        const payload = Object.fromEntries(Object.values(values).flatMap(i => Object.entries(i)))
        for (const [key, value] of Object.entries(payload)) {
            if (value === undefined || value === null) {
                payload[key] = 0
            }
        }
        return {query, payload}
    },
    target: exportResultFx
})


// confirmStatusFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Этап одобрен'}))
