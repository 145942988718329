import {Button, Card} from "antd";
import UploadImportSubForm from "./components/UploadImportSubForm.jsx";
import ManageImportSubList from "./components/ManageImportSubList.jsx";
import {useEvent, useStore} from "effector-react";
import {$showUpload, ImportSubListGate, setShowUploadEv} from "../../models/importSubstitutionModel/index.js";


const UploadImportReplacement = () => {
    const showUpload = useStore($showUpload)
    const setShowUpload = useEvent(setShowUploadEv)

    return <Card title={'Перечень товаров, подлежащих импортозамещению'}>
        <ImportSubListGate />
        <Button type={'primary'} onClick={() => setShowUpload(!showUpload)} style={{marginBottom: 16}}>
            {showUpload ? 'Отмена' : 'Загрузить список'}
        </Button>
        {showUpload && <UploadImportSubForm />}
        <ManageImportSubList />
    </Card>
}

export default UploadImportReplacement