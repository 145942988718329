import {createEffect} from "effector";
import {api} from "../../api/api.js";

export const getContractsListFx = createEffect()
    .use(async (search) => (await api().get(`/contracts/${search.length > 0 ? `?${search}` : ''}`)).data)

export const getContractFx = createEffect()
    .use(async (id) => (await api().get(`/contracts/${id}`)).data)

export const getStatusOptionsFx = createEffect()
    .use(async () => (await api().get('/directory_contract_statuses/')).data)

export const createStatusFx = createEffect()
    .use(async (payload) => (await api().post('/contract_statuses/', payload)).data)

export const confirmStatusFx = createEffect()
    .use(async ({payload, id}) => (await api().put(`/contract_statuses/${id}`, payload)).data)

export const rejectStatusFx = createEffect()
    .use(async ({payload, id}) => (await api().put(`/contract_statuses/${id}`, payload)).data)

export const updateStatusFx = createEffect()
    .use(async ({payload, id}) => (await api().put(`/contract_statuses/${id}`, payload)).data)