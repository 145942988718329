import {createEffect} from "effector/compat";
import {api} from "../../api/api.js";

export const getProfileFx = createEffect()
    .use(async () => (await api().get('/users/current')).data)

export const getNotificationsFx = createEffect()
    .use(async () => (await api().get('/notifications/')).data)

export const getReqStatusesFx = createEffect()
    .use(async () => (await api().get('/cert-requests/')).data)

export const getMatchesStatusesFx = createEffect()
    .use(async (search) => (await api().get(`/matches/${search.length > 0 ? `?${search}` : ''}`)).data)

export const getMatchDetsFx = createEffect()
    .use(async (id) => (await api().get(`/matches/${id}`)).data)

export const getProviderInfoFx = createEffect()
    .use(async (gov_id) => (await api().get(`/users_info/${gov_id}`)).data)

export const sendMatchMessageFx = createEffect()
    .use(async ({id, payload}) => {
        const formData = new FormData()
        for (const [field, value] of Object.entries(payload)) {
            if (field === 'files' && value?.length > 0) {
                value?.forEach(file => formData.append('files', file.originFileObj, file.originFileObj.name))
            } else if (value !== undefined) {
                formData.append(field, value)
            }
        }
        return (await api().post(`/matches/comment/${id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data
    })

export const downloadMatchFileFx = createEffect()
    .use(async ({id, name}) => {
            const file = await api().get(`/match_comment_files/download/${id}`, {responseType: 'blob'})
            const link = document.createElement('a')
            const url = URL.createObjectURL(file.data)
            link.href = url
            link.target = '_blank'
            link.download = name
            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        }
    )

export const submitEditFx = createEffect()
    .use(async (payload) => (await api().put('/users/', payload)).data)

export const changePasswordFx = createEffect()
    .use(async ({current_password, new_password}) => (await api().post('/users/change-password', {current_password, new_password})).data)

export const submitPotentialLotFx = createEffect()
    .use(async (payload) => (await api().put('/users/', payload)).data)

export const deleteLocalLotsFx = createEffect()
    .use(async () => (await api().delete('/lots/delete-local')).data)

export const deleteImportedLotsFx = createEffect()
    .use(async () => (await api().delete('/lots/delete-imported')).data)