import {combine, createStore} from 'effector';

export const $analyticsSt = createStore(null)
export const $analyticsGateStatusSt = createStore(false)

export const $modelResult = createStore([])
export const $resultsSelect = combine($modelResult, results => results
    ?.map((i, idx) => ({label: i?.short_description, value: idx}))
)
export const $selectedResult = createStore(null)
export const $modelModalState = createStore({open: false})