import {Button, Popconfirm, Table, Tooltip} from "antd";
import ViewDetailsModal from "../../ViewDetailsModal/ViewDetailsModal.jsx";
import {openViewModalEv} from "../../../models/viewDetailsModalModel/index.js";
import {useEvent, useStore} from "effector-react";
import {CloseOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import AddUserToBlacklistModal from "./AddUserToBlacklistModal.jsx";
import {$blacklist, excludeUserEv, setAddModalEv} from "../../../models/blacklistModel/index.js";

const ManageBlackList = () => {
    const blacklist = useStore($blacklist)

    const openViewModal = useEvent(openViewModalEv)
    const openAddModal = useEvent(setAddModalEv)
    const excludeUser = useEvent(excludeUserEv)

    const columns = [
        {
            title: 'БИН',
            dataIndex: 'gov_id',
        },
        {
            title: <Tooltip title={'Добавить поставщика в черный список'}>
                <Button className={'btn-primary'} icon={<PlusOutlined />} onClick={() => openAddModal(true)}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            render: (record) => {
                return <Button.Group>
                    <Tooltip title={'Просмотр поставщика'}>
                        <Button
                        className={'btn-primary'}
                        icon={<EyeOutlined/>}
                        onClick={() => openViewModal({type: 'supplier', supplierId: record.gov_id})}
                    />
                    </Tooltip>
                    <Popconfirm title={'Вы уверены, что хотите исключить этого поставщика из черного списка?'}
                                placement={'left'}
                                onConfirm={() => excludeUser(record.blacklist_id)}
                                okText={'Да'}
                                cancelText={'Нет'}
                    >
                        <Tooltip title={'Исключить поставщика из черного списка'}>
                            <Button className={'btn-danger'} icon={<CloseOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Button.Group>
            }
        }
    ]

    return <>
        <AddUserToBlacklistModal />
        <ViewDetailsModal />
        <Table columns={columns} dataSource={blacklist} pagination={false}/>
    </>
}

export default ManageBlackList