import {Card, Modal} from "antd";
import {useEvent, useStore} from "effector-react";
import {
    $selectedRequestInfo,
    $selectedRequestModal, getRegRequestByIdFx,
    resetSelectedRequestEv
} from "../../../models/regRequestsModel/index.js";
import TabList from "../../../components/TabList/TabList.jsx";

const ViewRequestModal = () => {
    const {visible} = useStore($selectedRequestModal)
    const loading = useStore(getRegRequestByIdFx.pending)
    const info = useStore($selectedRequestInfo)

    const resetModal = useEvent(resetSelectedRequestEv)

    return <Modal title={`Детали о заявке ${info?.full_name}`}
                  open={visible}
                  width={'80vw'}
                  onCancel={resetModal}
                  destroyOnClose={true}
                  footer={null}
    >
        <Card style={{width: '100%', border: 'none', padding: 0, margin: 0, height: '70vh', overflow: "auto"}} loading={loading}>
            <TabList data={info} dict={'regRequest'} exclude={excludeFields}/>
        </Card>
    </Modal>
}

const excludeFields = [
    'gov_id',
    'region',
    'id',
    'role',
    'created_at',
    'updated_at',
    'updated_by',
    'have_lots',
    'ens_tru'
]

export default ViewRequestModal