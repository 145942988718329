import {createEffect} from "effector";
import {api} from "../../api/api.js";

export const getRegRequestsListFx = createEffect()
    .use(async () => (await api().get('/users/unverified')).data)

export const getRegRequestByIdFx = createEffect()
    .use(async (id) => (await api().get(`/users/${id}`)).data)

export const verifyRequestFx = createEffect()
    .use(async (id) => (await api().post(`/users/verify/${id}`)).data)

export const submitRegisterRequestFx = createEffect()
    .use(async (payload) => (await api().post('/users/request', payload)).data)