import {createEffect} from "effector";
import {api} from "../../api/api.js";


export const getBiAccessTokenFx = createEffect()
    .use(async () => (await api().get(`/dashboards/1`)).data)

export const getModelResultFx = createEffect()
    .use(async ({query, payload}) => {
        const parsedQuery = new URLSearchParams(query).toString()
        return (await api().post(`/prioritization_model/?${parsedQuery}`, payload)).data
    })

export const exportResultFx = createEffect()
.use(async ({query, payload}) => {
    const file = await api().post(`/prioritization_model/export?distance=${query.distance}`, payload, {responseType: 'blob'})

    const link = document.createElement('a')
    const url = URL.createObjectURL(file.data)
    link.href = url
    link.target = '_blank'
    link.download = name
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
})