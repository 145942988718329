import {useEvent} from 'effector-react';
import {downloadMatchCommentFileEv} from '../../../models/profileModel/index.js';
import {DownloadOutlined} from '@ant-design/icons';
import {Divider} from 'antd';

const MessageFiles = ({files}) => {
    const download = useEvent(downloadMatchCommentFileEv)

    return <>
        <Divider orientation={'right'} orientationMargin={0} plain
                 style={{fontSize: 12, margin: 0}}
        >
            Прикрепленные файлы
        </Divider>
        <div style={{display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'flex-end', fontSize: 12}}>
            {files?.map(file => <div
                key={file.id}
                className={'link-like'}
                onClick={() => download({id: file.id, name: file.filename})}
            >
                <DownloadOutlined style={{marginRight: 8}}/>
                {file?.filename ?? '-'}
            </div>)
            }
        </div>
    </>
}

export default MessageFiles