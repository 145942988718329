export const ListDicts = {
    lot: {
        lot_number: 'Номер лота',
        customer: 'Закупщик',
        ens_tru: 'Код ЕНС ТРУ',
        customer_code: 'Код внутренней номенклатуры Заказчика',
        customer_product_group: 'Группа/Подгруппа товара согласно справочника Заказчика',
        short_description: 'Краткое описание',
        long_description: 'Длинное описание, техническая характеристика (при наличии)',
        manufacturer: 'Производитель',
        batch_number: 'Парт-номер',
        unit: 'единица измерения',
        average_consumption: 'Среднегодовое потребление (за последние три года)',
        buyer: 'Заказчик (предприятие)',
        year_of_purchase: 'год закупки',
        count: 'Количество',
        item_price: 'Стоимость за единицу',
        total_price: 'Итоговая сумма лота',
        delivery_date: 'Желаемая дата поставки',
        is_import_substitution: 'Подлежит импортозамещению',
        is_future: 'Предстоящий',
        lot_type: 'Тип лота',
    },

    cert: {
        // 'Действия',
        // '№',
        // 'Идентификатор',
        is_valid: 'Статус сертификата',
        authority_code: 'Код органа выдавшего сертификат',
        cert_id: '№ сертификата',
        cert_series: '№ бланка (серия сертификата)',
        cert_issue_year: 'Год выдачи',
        cert_issue_date: 'Дата выдачи сертификата',
        product_number: 'Номер (номер наименования товара в сертификате)',
        purpose_of_issuing_cert: 'Цель получения (выдачи) сертификата',
        tn_ved: 'ТН ВЭД',
        unit_quantity: 'Количество единицы товара',
        description_ru: 'Описание товара на русском языке',
        description_kz: 'Описание товара на государственном языке',
        share_of_local_content: 'Доля местного содержания',
        kp_ved_code: 'Код КП ВЭД',
        unit_code: 'Код единицы измерения (МКЕИ)',
        package_type: 'Вид упаковки',
        manufacturer_name_ru: 'Наименовние производителя на русском языке',
        manufacturer_name_kz: 'Наименовние производителя на государственном языке',
        manufacturer_bin: 'БИН производителя',
        manufacturer_rnn: 'РНН производителя',
        manufacturer_email: 'Электронный адрес производителя',
        manufacturer_webpage: 'Сайт производителя',
        manufacturer_phone: 'Телефон производителя',
        manufacturer_address_ru: 'Адрес производителя на русском языке',
        manufacturer_address_kz: 'Адрес производителя на государственном языке',
        kato: 'Код региона производителя (КАТО)',
        legal_form_of_the_exporter_ru: 'Организационно правовая форма экспортера на русском языке',
        legal_form_of_the_exporter_kz: 'Организационно правовая форма экспортера на государственном языке',
        full_name_of_the_head_ru: 'ФИО руководителя на русском языке',
        full_name_of_the_head_kz: 'ФИО руководителя на государственном языке',
        recipient_rnn: 'РНН получателя',
        recipient_bin: 'БИН получателя',
        recipient_name_ru: 'Наименование получателя на русском языке',
        recipient_name_kz: 'Наименование получателя на государственном языке',
        recipient_address_ru: 'Адрес получателя на русском языке',
        recipient_address_kz: 'Адрес получателя на государственном языке',
        cert_expiration_date: 'Дата окончания действия сертификата',
        register_date: 'Дата занесения в базу данных ИПКС',
        origin_criteria: 'Критерии происхождения'
    },

    supplier: {
        gov_id: 'БИН',
        name_ru: 'Наименовние производителя на русском языке',
        name_kz: 'Наименовние производителя на государственном языке',
        email: 'Электронный адрес',
        webpage: 'Сайт производителя',
        phone_number: 'Телефон производителя',
        region: 'Регион',
        address: 'Адрес',
        krp: 'КРП',
        fot_tax_2020: 'Налоги ФОТ за 2020',
        fot_tax_2021: 'Налоги ФОТ за 2021',
        fot_tax_2022: 'Налоги ФОТ за 2022',
        fot_tax_2023: 'Налоги ФОТ  за 2023',
        tax_2020: 'Налоги за 2020',
        tax_2021: 'Налоги за 2021',
        tax_2022: 'Налоги за 2022',
        tax_2023: 'Налоги за 2023',
        number_of_employees_2020: 'Кол-во сотрудников за 2020',
        number_of_employees_2021: 'Кол-во сотрудников за 2021',
        number_of_employees_2022: 'Кол-во сотрудников за 2022',
        number_of_employees_2023: 'Кол-во сотрудников за 2023',
    },

    regRequest: {
        gov_id: "БИН",
        organization: "Организация",
        full_name: "ФИО",
        division: "Отдел",
        job_title: "Должность",
        head: "Глава компании",
        purpose_of_use: "Цель регистрации",
        message: "Комментарий",
        email: "Электронная почта",
        web_page: "Сайт",
        phone_number: "Номер телефона",
        region: "Регион"
    },

    prioritization: {
        short_description: "Краткое описание",
        purchasing_score: "Оценка покупки",
        tnved_name: "ТНВЭД",
        revenue: "Выручка",
        revenue_percentile: "Процентиль выручки",
        income: "Доход",
        income_percentile: "Процентиль дохода",
        gva: "ВДС",
        gva_percentile: "Процентиль ВДС",
        labor: "Труд",
        labor_percentile: "Процентиль труда",
        tax: "Налоги",
        tax_percentile: "Процентиль налогов",
        industry_score: "Оценка отрасли",
        total_import: "Общий показатель импорта",
        cagr_import: "Среднегодовой темп роста импорта",
        volatility_mport: "Волатильность импорта",
        price_import: "Стоимость импорта",
        total_export: "Общий показатель экспорта",
        cagr_export: "Среднегодовой темп роста экспорта",
        volatility_export: "Волатильность экспорта",
        price_export: "Стоимость экспорта",
        product_complexity: "Сложность продукта",
        product_distance: "Дистанция продукта",
        product_opportunity_gain: "Потенциальная прибыль",
        product_rca: "RCA продукта (Анализ причин)",
        total_global_import: "Общий показатель глобального импорта",
        cagr_global_import: "Общий показатель Среднегодовых темпов роста",
        volatility_global_import: "Общий показатель волатильности глобального импорта",
        import_score: "Оценка импорта",
        total_score: "Общая оценка",
        industry_20: "Код индустрии 20",
        industry_68: "Код индустрии 68",
        industry_20_name: "Отрасль по коду 20",
        industry_68_name: "Отрасль по коду 68"
    }
}