import {createEvent} from "effector";
import {createGate} from "effector-react";

export const ImportSubListGate = createGate()

export const setAddModalEv = createEvent()
export const resetAddModalEv = createEvent()

export const addPosEv = createEvent()
export const excludePosEv = createEvent()

export const setShowUploadEv = createEvent()
export const uploadImportSubListEv = createEvent()

export const resetUploadImportEv = createEvent()