import {useStore} from "effector-react";
import {$requestsStatusesCerts} from "../../../models/profileModel/index.js";
import StatusBadge from "../../../components/StatusBadge/StatusBadge.jsx";
import {Table} from "antd";

const CertsRequestsTable = () => {
    const requests = useStore($requestsStatusesCerts)

    const columns = [
            {title: 'Номер лота', dataIndex: 'lot_id'},
            {title: 'Краткое описание', key: 'short_desc', render: (record) => record?.lot?.short_description ?? ''},
            {
                title: 'Статус',
                dataIndex: 'status',
                align: 'center',
                render: (s) => <StatusBadge status={s} cert={true}/>
            }
        ]

        return <Table dataSource={requests} columns={columns}/>
}

export default CertsRequestsTable