import {sample} from "effector";
import {$contractsList, $selectedContract, $stagesList, $viewContractStepsModalState} from "./stores.js";
import {
    confirmStatusFx,
    createStatusFx,
    getContractFx,
    getContractsListFx,
    getStatusOptionsFx, rejectStatusFx, updateStatusFx
} from "./effects.js";
import {
    approveByCustomerEv,
    approveByProviderEv,
    ContractsListGate, rejectByCustomerEv, rejectByProviderEv,
    setContractStepsModalEv, resetContractStepsModalEv, createStatusEv, updateStatusEv
} from "./events.js";
import notification from "antd/es/notification";

$contractsList.on(getContractsListFx.doneData, (state, payload) => payload)
    .reset(ContractsListGate.close)

$selectedContract.on(getContractFx.doneData, (state, payload) => payload)
    .reset(resetContractStepsModalEv)

$stagesList.on(getStatusOptionsFx.doneData, (state, payload) => payload)
    .reset(ContractsListGate.close)

$viewContractStepsModalState.on(setContractStepsModalEv, (state, payload) => ({visible: true, ...payload}))
    .reset(resetContractStepsModalEv)

sample({
    clock: ContractsListGate.open,
    target: getStatusOptionsFx
})

sample({
    clock: ContractsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getContractsListFx
})

sample({
    source: ContractsListGate.state,
    clock: [createStatusFx.doneData, confirmStatusFx.doneData, rejectStatusFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getContractsListFx
})

sample({
    source: $viewContractStepsModalState,
    clock: [createStatusFx.doneData, confirmStatusFx.doneData, rejectStatusFx.doneData],
    filter: (modal) => !!modal?.id,
    fn: (modal) => modal.id,
    target: getContractFx
})

sample({
    clock: $viewContractStepsModalState.updates,
    filter: (modal) => !!modal?.id,
    fn: (modal) => modal.id,
    target: getContractFx
})

sample({
    clock: createStatusEv,
    target: createStatusFx
})

sample({
    clock: updateStatusEv,
    fn: (payload) => {
        const {id} = payload
        delete payload.id
        return {payload, id}
    },
    target: updateStatusFx
})

sample({
    clock: approveByProviderEv,
    fn: (payload) => {
        const {id} = payload
        delete payload.id
        return {payload, id}
    },
    target: confirmStatusFx
})

sample({
    clock: rejectByProviderEv,
    fn: (payload) => {
        const {id} = payload
        delete payload.id
        return {payload, id}
    },
    target: rejectStatusFx
})

sample({
    clock: approveByCustomerEv,
    fn: (payload) => {
        const {id} = payload
        delete payload.id
        return {payload, id}
    },
    target: confirmStatusFx
})

sample({
    clock: rejectByCustomerEv,
    fn: (payload) => {
        const {id} = payload
        delete payload.id
        return {payload, id}
    },
    target: rejectStatusFx
})

createStatusFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Статус установлен'}))

confirmStatusFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Этап одобрен'}))
rejectStatusFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Этап отклонен'}))