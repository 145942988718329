import {useEvent, useStore} from 'effector-react';
import {
    $sendMessageStatus,
    sendMatchMessageEv,
    sendMatchMessageFx
} from '../../../models/profileModel/index.js';
import styles from './Messages.module.css'
import Button from 'antd/es/button';
import Input from 'antd/es/input'
import {PaperClipOutlined, SendOutlined} from '@ant-design/icons';
import Form from 'antd/es/form';
import {Tooltip, Upload} from 'antd';
import {useEffect} from 'react';

const SendMessageBlock = () => {
    const sendLoading = useStore(sendMatchMessageFx.pending)
    const sendStatus = useStore($sendMessageStatus)
    const [msgForm] = Form.useForm()

    useEffect(() => {
        if (sendStatus) {
            msgForm.resetFields()
        }
    }, [sendStatus]);

    const sendMessage = useEvent(sendMatchMessageEv)

    const onSendMsg = ({comment, files}) => {
        sendMessage({comment, files: files?.fileList ?? undefined})
    }

    return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <Form layout={'vertical'} form={msgForm} onFinish={(values) => onSendMsg(values)}
              style={{width: '100%'}}
        >
            <Form.Item name={'comment'} rules={[{required: true, message: 'Введите текст'}]}
                       style={{marginBottom: 0}}
            >
                <Input.TextArea className={styles.messageInput}
                                rows={3}
                                placeholder={'Новое сообщение...'}
                />
            </Form.Item>
            <Form.Item name={'files'} style={{marginBottom: 0}}>
                <Upload beforeUpload={() => false} multiple={true}>
                    <div style={{
                        display: 'flex',
                        gap: 4,
                        alignItems: 'center',
                        fontSize: 12
                    }}
                         className={'link-like'}>
                        <PaperClipOutlined />
                        <span>Прикрепить файлы</span>
                    </div>
                </Upload>
            </Form.Item>
        </Form>
        <Tooltip title={'Отправить'}>
        <Button type={'primary'}
                style={{height: 75}}
                icon={<SendOutlined />}
                onClick={msgForm.submit}
                loading={sendLoading}
                size={'large'}
        />
        </Tooltip>
    </div>
}

export default SendMessageBlock