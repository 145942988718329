import {Button, Card, Popconfirm, Table} from "antd";

const PreviewPlan = ({cancelPlan, confirmPlan, data}) => {
    return <Card
        title={'Предпросмотр плана'}
        style={{marginTop: 24}}
        extra={
            <Button.Group style={{gap: 1}}>
                <Popconfirm title={'Вы уверены, что хотите удалить загруженный план?'}
                            onConfirm={() => cancelPlan()}
                            placement={'left'}
                            okText={'Да'}
                            cancelText={'Нет'}
                            okButtonProps={{className: 'btn-primary'}}
                            cancelButtonProps={{className: 'btn-secondary'}}
                >
                    <Button className={'btn-danger'}>
                        Удалить план
                    </Button>
                </Popconfirm>
                <Popconfirm title={'Вы уверены? Данное действие необратимо.'}
                            onConfirm={() => confirmPlan()}
                            placement={'left'}
                            okText={'Да'}
                            cancelText={'Нет'}
                            okButtonProps={{className: 'btn-primary'}}
                            cancelButtonProps={{className: 'btn-secondary'}}
                >
                    <Button type={'primary'} className={'btn-primary'}>
                        Подтвердить план
                    </Button>
                </Popconfirm>
            </Button.Group>
        }
    >
        <Table
            dataSource={data}
            bordered={true}
            columns={previewColumns}
            pagination={{position: ['bottomCenter'], pageSizeOptions: [10, 20, 50, 100], defaultPageSize: 20}}
        />
    </Card>
}

export default PreviewPlan

const previewColumns = [
    {
        title: 'Код ЕНС ТРУ',
        key: 'enstru',
        dataIndex: 'ens_tru'
    },
    {
        title: 'Краткое описание',
        key: 'name',
        dataIndex: 'short_description'
    },
    {
        title: 'Длинное описание (при наличии)',
        key: 'long_desc',
        dataIndex: 'long_description'
    },
    {
        title: 'Количество',
        key: 'count',
        dataIndex: 'count'
    },
    {
        title: 'Стоимость за ед.',
        key: 'unit_price',
        dataIndex: 'item_price'
    },
    {
        title: 'Итоговая сумма',
        key: 'total',
        dataIndex: 'total_price'
    },
    {
        title: 'Желаемая дата поставки',
        key: 'dd',
        dataIndex: 'delivery_date'
    }
]