import {$addUserModal, $blacklist, $showUpload} from "./stores.js";
import {
    addUserToBlacklistFx,
    excludeUserFromBlacklistFx,
    getBlackListFx,
    gmkAddToWhitelistFx, gmkExcludeFromWhitelistFx,
    uploadBlackListFx
} from './effects.js';
import {
    addUserEv,
    BlackListGate,
    excludeUserEv, gmkAddToWhitelistEv, gmkExcludeFromWhitelistEv,
    resetAddModalEv,
    setAddModalEv,
    setShowUploadEv,
    uploadBlackListEv
} from './events.js';
import {sample} from "effector";
import notification from "antd/es/notification";

$blacklist.on(getBlackListFx.doneData, (_, payload) => payload)
    .reset(BlackListGate.close)

$addUserModal.on(setAddModalEv, (_, payload) => payload)
    .reset([addUserToBlacklistFx.doneData, resetAddModalEv])

$showUpload.on(uploadBlackListFx.doneData, () => false)
    .on(setShowUploadEv, (_, payload) => payload)

sample({
    clock: [
        BlackListGate.open,
        uploadBlackListFx.doneData,
        excludeUserFromBlacklistFx.doneData,
        addUserToBlacklistFx.doneData,
        gmkAddToWhitelistFx.doneData,
        gmkExcludeFromWhitelistFx.doneData
    ],
    target: getBlackListFx
})

sample({
    clock: uploadBlackListEv,
    target: uploadBlackListFx
})

sample({
    clock: excludeUserEv,
    target: excludeUserFromBlacklistFx
})

sample({
    clock: addUserEv,
    target: addUserToBlacklistFx
})

sample({
    clock: gmkAddToWhitelistEv,
    target: gmkAddToWhitelistFx
})

sample({
    clock: gmkExcludeFromWhitelistEv,
    target: gmkExcludeFromWhitelistFx
})

uploadBlackListFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Черный список загружен'}))
addUserToBlacklistFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Поставщик добавлен в черный список'}))
excludeUserFromBlacklistFx.doneData.watch(ev => ev && notification.success({message: 'Успешно', description: 'Поставщик исключен из черного списка'}))