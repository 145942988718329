import {Button, Col, Popconfirm, Row, Table, Tooltip, Typography} from "antd";
import FilterBlock from "../../components/FilterBlock/FilterBlock.jsx";
import {
    $lotsList,
    closeLotEv,
    deleteLotsEv,
    getLotsListFx,
    LotsListGate
} from '../../models/lotsModel/index.js';
import {useStore} from "effector-react";
import {$isAllowed} from "../../models/profileModel/index.js";
import {
    CaretDownOutlined,
    CheckOutlined,
    CloseOutlined, DeleteOutlined,
    FileDoneOutlined,
    InfoOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import {useEvent} from "effector-react/compat";
import {requestCertEv} from "../../models/matchesModel/index.js";
import {usePaginationControl} from "../../hooks/pagination-hook.js";
import {openViewModalEv} from "../../models/viewDetailsModalModel/index.js";
import ViewDetailsModal from "../ViewDetailsModal/ViewDetailsModal.jsx";
import ControlledEllipsis from '../../components/ControlledEllipsis/ControlledEllipsis.jsx';
import {useState} from 'react';


const Lots = () => {
    const loading = useStore(getLotsListFx.pending)
    const {count, data} = useStore($lotsList)
    const requestCert = useEvent(requestCertEv)
    const closeLot = useEvent(closeLotEv)
    const deleteLots = useEvent(deleteLotsEv)

    const openViewModal = useEvent(openViewModalEv)

    const perms = useStore($isAllowed)

    const [curPage, limit, onChangePagination, search] = usePaginationControl()

    const [selectedLots, setSelectedLots] = useState([])

    const columns = [
        {
            title: 'Код ЕНС ТРУ',
            key: 'enstru',
            width: '10%',
            dataIndex: 'ens_tru',
            render: (ens) => <Typography.Paragraph>{ens}</Typography.Paragraph>
        },
        {
            title: 'Краткое описание',
            key: 'name',
            width: '15%',
            dataIndex: 'short_description',
            render: (sd) => <Typography.Paragraph>{sd}</Typography.Paragraph>
        },
        {
            title: 'Длинное описание (при наличии)',
            key: 'long_desc',
            width: '15%',
            dataIndex: 'long_description',
            render: (ld) => <ControlledEllipsis text={ld}/>
        },
        {
            title: 'Количество',
            key: 'count',
            dataIndex: 'count'
        },
        {
            title: 'Стоимость за ед.',
            key: 'unit_price',
            dataIndex: 'item_price'
        },
        {
            title: 'Итоговая сумма',
            key: 'total',
            dataIndex: 'total_price'
        },
        {
            title: 'Желаемая дата поставки',
            key: 'dd',
            dataIndex: 'delivery_date',
            render: (dd) => <Typography.Paragraph>{dd}</Typography.Paragraph>
        },
        {
            title: 'Активен',
            key: 'active',
            dataIndex: 'completed',
            align: 'center',
            render: (status) => status ? <CloseOutlined/> : <CheckOutlined/>
        },
        {
            title: 'Импортный',
            key: 'imported',
            dataIndex: 'lot_type',
            width: '5%',
            align: 'center',
            render: (type) => type === 'imported' ? <CheckOutlined /> : ''
        },
        {
            title: 'Импорто-\nзамещение',
            key: 'import',
            dataIndex: 'is_import_substitution',
            width: '5%',
            align: 'center',
            render: (imp) => imp ? <CheckOutlined /> : ''
        },
        {
            key: 'actions',
            width: perms.supplier ? '5%' : 0,
            render: (record) => <Button.Group>
                {(perms.supplier || perms.admin) && !record.have_match && <Popconfirm
                    title={'Вы уверены, что хотите подать заявку на сертификат по данной позиции?'}
                    okText={'Да'}
                    cancelText={'Нет'}
                    placement={'left'}
                    disabled={record?.cert_requested}
                    okButtonProps={{className: 'btn-primary'}}
                    cancelButtonProps={{className: 'btn-secondary'}}
                    onConfirm={() => requestCert(record.id)}
                >
                    <Tooltip title={record?.cert_requested ? 'Заявка на сертификат уже подана' : 'Подать заявку на сертификат'}>
                        <Button
                            className={record?.cert_requested ? null : 'btn-primary'}
                            icon={<SolutionOutlined/>}
                            style={record?.cert_requested ? {borderTopLeftRadius: 3, borderBottomLeftRadius: 3} : null}
                            disabled={record?.cert_requested}
                        />
                    </Tooltip>
                </Popconfirm>
                }
                {(perms.buyer || perms.admin) && <Tooltip
                    title={'Закрыть лот'}
                    children={
                        <Popconfirm title={'Вы уверены, что хотите закрыть данный лот?'}
                                    okText={'Да'}
                                    cancelText={'Нет'}
                                    placement={'left'}
                                    okButtonProps={{className: 'btn-primary'}}
                                    cancelButtonProps={{className: 'btn-secondary'}}
                                    onConfirm={() => closeLot(record.id)}
                        >
                            <Button type={'primary'} className='btn-primary' icon={<FileDoneOutlined/>}/>
                        </Popconfirm>
                    }
                />}
                <Tooltip
                    title={'Детали'}
                    children={
                        <Button className='info-btn'
                                icon={<InfoOutlined/>}
                                onClick={() => openViewModal({id: record.id, type: 'lot'})}
                        />
                    }
                />
            </Button.Group>
        }
    ]

    const filters = [
        {name: 'name', label: 'Краткое описание', type: 'input'},
        {name: 'ens_tru', label: 'Код ЕНС ТРУ', type: 'input'},
        {name: 'year_of_purchase', label: 'Год закупки', type: 'date'},
        {name: 'completed', label: 'Завершен', type: 'switch'}
    ]

    if (perms.buyer) {
        filters.splice(3, 0,{name: 'customer_code', label: 'Код внутренней номенклатуры Заказчика', type: 'input'})
    }

    return <>
        <LotsListGate search={search.toString()}/>
        <ViewDetailsModal/>
        <Col span={24}>
            <Row><FilterBlock items={filters}/></Row>
            {selectedLots.length > 0 && <Row style={{marginTop: 24}}>
                <Popconfirm onConfirm={() => deleteLots(selectedLots)}
                            title={'Вы уверены, что хотите удалить выбранные лоты?'}
                            okText={'Да'}
                            okButtonProps={{danger: true}}
                            placement={'right'}
                >
                    <Button type='primary' danger icon={<DeleteOutlined/>}>Удалить выбранные</Button>
                </Popconfirm>
                <Button onClick={() => setSelectedLots([])} style={{marginLeft: 24}}>Отмена</Button>
            </Row>}
            <Row style={{marginTop: 24}} id='lots-table'>
                <Table columns={columns}
                       dataSource={data}
                       loading={loading}
                       style={{width: '100%'}}
                       bordered={true}
                       onChange={({current, pageSize}) => onChangePagination(current, pageSize)}
                       rowKey={'id'}
                       rowSelection={{
                           type: 'checkbox',
                           onChange: (keys) => setSelectedLots(keys),
                           selectedRowKeys: selectedLots
                        }}
                       pagination={{
                           position: ['bottomCenter'],
                           pageSize: parseInt(limit),
                           current: parseInt(curPage),
                           total: count
                       }}
                />
            </Row>
        </Col>
    </>
}

export default Lots