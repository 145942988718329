import {createGate} from "effector-react";
import {createEvent} from 'effector';

export const AnalyticsGate = createGate()

export const submitModelFormEv = createEvent()

export const selectResultEv = createEvent()

export const resetModelModalEv = createEvent()

export const exportResultEv = createEvent()
