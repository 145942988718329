import {useStore} from 'effector-react';
import {$isAllowed} from '../../../models/profileModel/index.js';
import {useMemo} from 'react';
import {Button, Dropdown, Tooltip} from 'antd';
import {
    CheckCircleOutlined, FileProtectOutlined, FileUnknownOutlined,
    InteractionOutlined, LockOutlined,
    LogoutOutlined, ScheduleOutlined, SolutionOutlined,
    UserOutlined
} from '@ant-design/icons';
import {useEvent} from 'effector-react/compat';
import {logoutEv} from '../../../models/authModel/index.js';

const ProfileDropdown = () => {
    const logout = useEvent(logoutEv)
    const perms = useStore($isAllowed)


    const dropdownItems = useMemo(() => {
        const items = [
            {
                key: 'profile',
                label: <Tooltip title={'Контактные данные предприятия'} placement={'left'}>
                    <Button className='info-btn' icon={<UserOutlined/>} href={'/profile'}/>
                </Tooltip>
            },
            {
                key: 'logout',
                label: <Tooltip title={'Выйти из аккаунта'} placement={'left'}>
                    <Button className='btn-danger' icon={<LogoutOutlined/>} onClick={() => logout()}/>
                </Tooltip>
            },
        ]

        if (perms.supplier || perms.buyer || perms.admin) {
            items.unshift({
                key: 'statuses',
                label: <Tooltip title={`Просмотр статусов моих ${perms.supplier ? 'заявок и лотов' : 'лотов'}`} placement={'left'}>
                    <Button className='info-btn' icon={<CheckCircleOutlined/>} href={'/view-statuses'}/>
                </Tooltip>
            })
        }

        // if (!perms.investor) {
        //     items.unshift({
        //             key: 'contracts',
        //             label: <Tooltip title={perms.chamber || perms.gov ? 'Контракты' : 'Мои контракты'} placement={'left'}>
        //                 <Button className='info-btn' icon={<AuditOutlined/>} href={'/contracts'}/>
        //             </Tooltip>
        //         }
        //     )
        // }

        if (perms.gov) {
            items.unshift(
                {
                    key: 'import',
                    label: <Tooltip title={'Загрузка перечня импортозамещения'} placement={'left'}>
                        <Button id="header-dropdown" className="info-btn"
                                icon={<InteractionOutlined/>} href={'/import-replacement'}/>
                    </Tooltip>
                },
                {
                    key: 'plan-confirm',
                    label: <Tooltip title={'Подтверждение плана'} placement={'left'}>
                        <Button id="header-dropdown" className="info-btn" icon={<FileProtectOutlined />}
                                href={'/gov-plan-approve'}/>
                    </Tooltip>
                }
            )
        }

        if (perms.chamber) {
            items.unshift(
                {
                    key: 'blacklist',
                    label: <Tooltip title={'Управление черным списком поставщиков'} placement={'left'}>
                        <Button className='info-btn' icon={<LockOutlined/>} href={'/blacklist'}/>
                    </Tooltip>
                },
                {
                    key: 'regRequests',
                    label: <Tooltip title={'Заявки на регистрацию'} placement={'left'}>
                        <Button className='info-btn' icon={<FileUnknownOutlined/>}
                                href={'/requests-approve'}/>
                    </Tooltip>
                }
            )
        }

        if (perms.buyer) {
            items.unshift(
                {
                    key: 'annual_plan',
                    label: <Tooltip title={'Загрузка годового плана'} placement={'left'}>
                        <Button className='info-btn' icon={<ScheduleOutlined />} href={'/annual-plan'}/>
                    </Tooltip>
                },
                {
                    key: 'blacklist',
                    label: <Tooltip title={'Управление черным списком поставщиков'} placement={'left'}>
                        <Button className='info-btn' icon={<LockOutlined/>} href={'/blacklist'}/>
                    </Tooltip>
                },
            )
        }

        return items
    }, [perms])

    return <>
        <Dropdown id='header-dropdown' menu={{items: dropdownItems}} placement={'bottom'}>
            <Tooltip title={'Личный кабинет'} placement={'left'}>
                <Button  className='info-btn' icon={<SolutionOutlined />} />
            </Tooltip>
        </Dropdown>
    </>
}

export default ProfileDropdown