import {createEvent} from "effector";
import {createGate} from "effector-react";

export const RegRequestsGate = createGate()

export const selectRequestEv = createEvent()
export const resetSelectedRequestEv = createEvent()

export const verifyRequestEv = createEvent()

export const submitRegisterRequestEv = createEvent()