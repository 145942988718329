import {useEvent, useStore} from "effector-react";
import {
    $importUploadStatus,
    resetUploadImportEv,
    uploadImportSubListEv,
    uploadImportSubListFx
} from "../../../models/importSubstitutionModel/index.js";
import {Button, Card, Form, Row} from "antd";
import {CheckCircleOutlined, UploadOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

const UploadImportSubForm = () => {
    const uploaded = useStore($importUploadStatus)
    const loading = useStore(uploadImportSubListFx.pending)
    const reset = useEvent(resetUploadImportEv)

    const upload = useEvent(uploadImportSubListEv)

    const [importForm] = Form.useForm()
    const files = Form.useWatch('file', importForm)

    return <Card loading={loading}
                 extra={uploaded ? <CheckCircleOutlined style={{color: 'green', fontSize: 28}}/> : null}
    >
        {uploaded
            ? <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <p>Перечень импортозамещения успешно загружен</p>
                <Button className={'btn-primary'} onClick={() => reset()} style={{width: 'max-content'}}>Загрузить снова</Button>
            </div>
            : <>
                <Form form={importForm}
                      name={'uploadImportForm'}
                      onFinish={(v) => upload(v?.file?.fileList[0])}
                      layout={'vertical'}
                >
                    <Form.Item name={'file'} valuePropName={'file'}>
                        <Dragger
                            name={'file'}
                            disabled={uploaded}
                            maxCount={1}
                            multiple={false}
                            beforeUpload={() => false}
                            style={{width: '30%'}}
                        >
                            <UploadOutlined style={{fontSize: 48}}/>
                            <p>Кликните чтобы выбрать файл или перетащите файл в данное поле</p>
                        </Dragger>
                    </Form.Item>
                </Form>
                <Row style={{gap: 8}}>
                    <Button type={'primary'}
                            className={(!files?.fileList || files?.fileList?.length < 1) ? '' : 'btn-primary'}
                            onClick={() => importForm.submit()}
                            disabled={!files?.fileList || files?.fileList?.length < 1}
                    >
                        Загрузить
                    </Button>
                </Row>
            </>}
    </Card>
}

export default UploadImportSubForm