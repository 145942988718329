import {$certsList, $selectedCert, $selectedCertSupplier} from "./stores.js";
import {getCertFx, getCertsListFx, getCertSupplierFx} from "./effects.js";
import {CertsListGate, SelectedCertGate, SelectedCertSupplierGate} from "./events.js";
import {sample} from "effector";

$certsList.on(getCertsListFx.doneData, (state, payload) => payload)
    .reset(CertsListGate.close)

$selectedCert.on(getCertFx.doneData, (state, payload) => payload)
    .reset(SelectedCertGate.close)

$selectedCertSupplier.on(getCertSupplierFx.doneData, (state, payload) => payload)
    .reset(SelectedCertSupplierGate.close)

sample({
    clock: CertsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getCertsListFx
})

sample({
    clock: SelectedCertGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id') && typeof gate.id === 'number',
    fn: (gate) => gate.id,
    target: getCertFx
})

sample({
    clock: SelectedCertSupplierGate.state,
    filter: (gate) => Object.hasOwn(gate, 'bin') && typeof gate.bin === 'string',
    fn: (gate) => gate.bin,
    target: getCertSupplierFx
})