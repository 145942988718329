import {Button, Form, Input} from "antd";
import {useEvent} from "effector-react";
import {sendTicketEv} from "../../models/viewDetailsModalModel/index.js";

const TicketForm = () => {

	const sendTicket = useEvent(sendTicketEv)
	const onFinish = (values) => {
		console.log(values)
		sendTicket(values)
	}

	return (
		<Form
			name={'ticket_form'}
			onFinish={onFinish}
			wrapperCol={{
				span: 12,
			}}
		>
			<Form.Item label='input 1' name='input 1'>
				<Input />
			</Form.Item>
			<Form.Item label='input 2' name='input 2'>
				<Input />
			</Form.Item>
			<Form.Item label='input 3' name='input 3'>
				<Input />
			</Form.Item>
			<Form.Item>
				<Button type='primary' htmlType={'submit'}>Submit</Button>
			</Form.Item>
		</Form>
	)
}

export default TicketForm
