import {Card, Divider, Select, Tabs} from 'antd';
import {useEvent, useStore, useStoreMap} from 'effector-react';
import {
    $gmkList,
    $selectedGmk,
    GovCustomersGate,
    selectGmkEv
} from '../../models/uploadModel/index.js';
import ConfirmLocal from './components/ConfirmLocal.jsx';
import ConfirmImport from './components/ConfirmImport.jsx';

const UploadPlanConfirm = () => {
    const gmkSelectOpts = useStoreMap($gmkList, list => list.map(
        gmk => ({
            label: gmk.organization,
            value: gmk.id
        })
    ))

    const selectGmk = useEvent(selectGmkEv)

    const selectedGmk = useStore($selectedGmk)

    const tabs = [
        {
            key: 'local',
            label: 'Местные',
            children: <ConfirmLocal/>
        },
        {
            key: 'import',
            label: 'Импортируемые',
            children: <ConfirmImport/>
        }
    ]

    return <>
        <GovCustomersGate />
        <Card>
            <div style={{display: 'flex', gap: 4, flexDirection: 'column'}}>
                <span>Выберите ГМК:</span>
                <Select options={gmkSelectOpts} onSelect={selectGmk}/>
            </div>
        </Card>
        <Divider/>
        {selectedGmk && <Tabs defaultActiveKey={'local'} items={tabs}/>}
    </>
}

export default UploadPlanConfirm