import {Button, Card, Popconfirm, Table} from 'antd';
import {useStore} from 'effector-react';
import {
    $govPlanPreview,
    $selectedGmk,
    govApprovePlanEv
} from '../../../models/uploadModel/index.js';
import {useEvent} from 'effector-react/compat';
import {previewColumns} from './columns.js';

const ConfirmLocal = () => {
    const data = useStore($govPlanPreview)
    const selectedGmk = useStore($selectedGmk)

    const confirmPlan = useEvent(govApprovePlanEv)

    return <Card
        title={`Предпросмотр плана местных закупок - ${selectedGmk?.organization ?? '-'}`}
        extra={
            <Popconfirm title={'Вы уверены? Данное действие необратимо.'}
                        onConfirm={() => confirmPlan()}
                        placement={'left'}
                        okText={'Да'}
                        cancelText={'Нет'}
                        okButtonProps={{className: 'btn-primary'}}
                        cancelButtonProps={{className: 'btn-secondary'}}
            >
                <Button type={'primary'} className={'btn-primary'}>
                    Подтвердить план
                </Button>
            </Popconfirm>
        }
    >
        <Table
            dataSource={data}
            bordered={true}
            columns={previewColumns}
            pagination={{
                position: ['bottomCenter'],
                pageSizeOptions: [10, 20, 50, 100],
                defaultPageSize: 20
            }}
        />
    </Card>
}

export default ConfirmLocal