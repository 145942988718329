import {createEffect} from "effector";
import {api} from "../../api/api.js";

export const getCertsListFx = createEffect()
    .use(async (search) => (await api().get(`/certs/${search.length > 0 ? `?${search}` : ''}`)).data)

export const getCertFx = createEffect()
    .use(async (id) => (await api().get(`/certs/${id}`)).data)

export const getCertSupplierFx = createEffect()
    .use(async (bin) => (await api().get(`/users_info/${bin}`)).data)