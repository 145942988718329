import Card from "antd/es/card";
import Row from "antd/es/row";
import {Button, Form, Input} from "antd";
import {useStore} from "effector-react";
import {changePasswordEv, changePasswordFx} from "../../models/profileModel/index.js";
import {useEvent} from "effector-react/compat";
import {useState} from "react";

export const ChangePasswordForm = () => {

    const [passForm] = Form.useForm()

    const loading = useStore(changePasswordFx.pending)
    const onConfirm = useEvent(changePasswordEv)

    const [formValid, setFormValid] = useState(false);

    const validPass = new RegExp('^(?=.*[a-zA-Z0-9_!@#$%^&?*])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&?*])[a-zA-Z0-9_!@#$%^&?*]{8,32}$')

    const onSubmit = (values) => {
        onConfirm(values)
    }


    return <Row align={'middle'} justify={'start'} style={{marginBottom: 24}}>
        <Card style={{width: '50%'}} bodyStyle={{display: 'flex', gap: 24, flexDirection: 'column'}}>
            <Form form={passForm} layout={'vertical'} onFinish={(v) => onSubmit(v)}>
                <Form.Item name={'current_password'} label={'Текущий пароль'}
                           rules={[{required: true, message: 'Введите текущий пароль'}]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item name={'new_password'} label={'Придумайте новый пароль'}
                           rules={[
                               {required: true, message: 'Введите новый пароль'},
                               ({getFieldValue}) => ({
                                   validator(_, value) {
                                       const oldPass = getFieldValue('current_password')
                                       const match = validPass.test(value)
                                       if (value !== oldPass && match) {
                                           return Promise.resolve();
                                       } else {
                                           if (!match) {
                                               return Promise.reject(new Error('Пароль должен содержать символы a-z, A-Z, 0-9, _!@#$%^&?*, длина 8-32'))
                                           } else {
                                               return Promise.reject(new Error('Новый пароль должен отличаться от старого'));
                                           }
                                       }
                                   },
                               }),
                           ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item name={'confirm_password'} label={'Подтвердите пароль'}
                           dependencies={['new_password']}
                           rules={[
                               {required: true, message: 'Подтвердите пароль'},
                               ({getFieldValue}) => ({
                                   validator(_, value) {
                                       if (getFieldValue('new_password') === value) {
                                           setFormValid(true)
                                           return Promise.resolve();
                                       } else {
                                           setFormValid(false)
                                           return Promise.reject(new Error('Пароли не совпадают'));
                                       }
                                   },
                               }),
                           ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
            <Button type={'primary'} style={{width: 'max-content'}}
                    onClick={() => passForm.submit()}
                    disabled={!formValid}
                    loading={loading}
                    className={formValid ? 'btn-primary' : ''}
            >
                Подтвердить
            </Button>
        </Card>
    </Row>
}
