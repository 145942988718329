import {Button, Card, Form, Input, Tooltip} from "antd";
import {useEvent} from "effector-react/compat";
import {$authStatus, loginEv, loginFx} from "../../models/authModel/index.js";
import {useStore} from "effector-react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {cookies} from "../../api/api.js";
import {QuestionCircleOutlined} from "@ant-design/icons";

const Login = () => {
    const onLogin = useEvent(loginEv)
    const loading = useStore(loginFx.pending)
    const authStatus = useStore($authStatus)

    const navigate = useNavigate()

    useEffect(() => {
        if (authStatus) {
            const token = cookies.get('access_token')
            if (token) {
                navigate('/')
            }
        }
    }, [authStatus]);

    return <Card className={'login-form'} id='login-form'>
        <Form name={'loginForm'} id={'loginForm'}
              onFinish={onLogin}
              layout={'vertical'}
        >
            <Form.Item name={'username'} label={
                <div style={{display: 'flex', gap: 8}}>
                    <span>БИН</span>
                    <Tooltip
                        title={'Если Вы - инвестор, то введите email, указанный при регистрации'}><QuestionCircleOutlined/></Tooltip>
                </div>
            }>
                <Input/>
            </Form.Item>
            <Form.Item name={'password'} label={'Пароль'}>
                <Input.Password allowClear={true}/>
            </Form.Item>
            <Button htmlType={'submit'} form={'loginForm'} type={'primary'} loading={loading} className="login-btn">
                Войти
            </Button>
            <Button type={'link'} href={'/register'}>Регистрация через ЭЦП</Button>
            <Button type={'link'} href={'/register-request'}>Подать заявку на регистрацию</Button>
        </Form>
    </Card>
}

export default Login