import {Button, Card, Form, Row} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {UploadOutlined} from "@ant-design/icons";
import {uploadBlackListEv, uploadBlackListFx} from "../../../models/blacklistModel/index.js";
import {useEvent, useStore} from "effector-react";

const UploadBlackList = () => {
    const uploadLoading = useStore(uploadBlackListFx.pending)
    const upload = useEvent(uploadBlackListEv)

    const [form] = Form.useForm()
    const files = Form.useWatch('file', form)

    return <>
        <Card style={{marginBottom: 16}}>
        <Form form={form}
              name={'uploadBlacklistForm'}
              onFinish={(v) => upload(v?.file?.fileList[0])}
              layout={'vertical'}
        >
            <Form.Item name={'file'} valuePropName={'file'}>
                <Dragger
                    name={'file'}
                    maxCount={1}
                    multiple={false}
                    beforeUpload={() => false}
                    style={{width: '30%'}}
                >
                    <UploadOutlined style={{fontSize: 48}}/>
                    <p>Кликните чтобы выбрать файл или перетащите файл в данное поле</p>
                </Dragger>
            </Form.Item>
        </Form>
        <Row style={{gap: 8}}>
            <Button type={'primary'}
                    className={(!files?.fileList || files?.fileList?.length < 1) ? '' : 'btn-primary'}
                    onClick={() => form.submit()}
                    disabled={!files?.fileList || files?.fileList?.length < 1}
                    loading={uploadLoading}
            >
                Загрузить
            </Button>
        </Row>
        </Card>
    </>
}

export default UploadBlackList