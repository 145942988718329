import {combine, createStore} from "effector";
import {prepareStages} from "./utils.js";

export const $contractsList = createStore({count: 0, data: []})

export const $selectedContract = createStore({})

export const $stagesList = createStore([])
export const $statusOptions = combine($stagesList, stages => Object.fromEntries(
    stages.map(i => [i.name, [...i.options, {name: 'На рассмотрении', id: 0}].map(opt => ({label: opt.name, value: opt.id}))])
))

export const $calculatedStages = combine($selectedContract, contract => prepareStages(contract?.stages ?? undefined))

export const $viewContractStepsModalState = createStore({visible: false, id: null})