import StatusBadge from "../../../components/StatusBadge/StatusBadge.jsx";
import {Button, Popconfirm, Table, Tooltip} from "antd";
import {useStore} from "effector-react";
import {
    $isAllowed,
    $requestsStatusesMatches,
    setMatchModalState
} from '../../../models/profileModel/index.js';
import {useEffect, useMemo} from 'react';
import {CheckOutlined, CloseOutlined, SolutionOutlined} from '@ant-design/icons';
import {useEvent} from "effector-react/compat";
import {approveByCustomerEv, rejectByCustomerEv} from "../../../models/matchesModel/index.js";
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const MatchesStatusesTable = () => {
    const role = useStore($isAllowed)
    const matches = useStore($requestsStatusesMatches)

    const approveBuy = useEvent(approveByCustomerEv)
    const rejectBuy = useEvent(rejectByCustomerEv)

    const showDetails = useEvent(setMatchModalState)

    const location = useLocation()
    const {id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname.includes('view-statuses/match') && !!id) {
            showDetails({open: true, id: Number(id)})
        }
    }, [location, id]);

    const showButtons = (match) => {
        if (!match.id) {
            return false
        } else if (role.buyer && match.customer_approve !== null) {
            return false
        } else return true
    }

    const columns = useMemo(() => {
        const cols = [
            {title: 'Номер лота', dataIndex: 'lot_id'},
            {title: 'Краткое описание', dataIndex: 'short_description'},
            {
                title: 'Статус',
                dataIndex: 'status',
                align: 'center',
                render: (status, record) => <StatusBadge
                    status={status}
                    cert={false}
                    appProv={record?.provider_approve ?? null}
                    appCust={record?.customer_approve ?? null}
                />
            }
        ]

        if (role.buyer) {
            cols.push({
                key: 'actions',
                width: '10%',
                align: 'center',
                render: (record) => showButtons(record) ? <Button.Group style={{display: 'flex', gap: '1px', justifyContent: "center"}}>
                        <Popconfirm
                            title={'Вы уверены, что хотите подтвердить участие в данном лоте?'}
                            onConfirm={() => approveBuy(record.id)}
                            placement={'left'}
                            okText={'Да'}
                            cancelText={'Нет'}
                            okButtonProps={{className: 'btn-primary'}}
                            cancelButtonProps={{className: 'btn-secondary'}}
                        >
                            <Tooltip
                                title={'Подтвердить'}
                                children={<Button className='btn-primary' icon={<CheckOutlined/>}/>}
                            />
                        </Popconfirm>
                        <Popconfirm
                            title={'Вы уверены, что хотите отказаться от участия в данном лоте?'}
                            onConfirm={() => rejectBuy(record.id)}
                            placement={'left'}
                            okText={'Да'}
                            cancelText={'Нет'}
                            okButtonProps={{className: 'btn-primary'}}
                            cancelButtonProps={{className: 'btn-secondary'}}
                        >
                            <Tooltip
                                title={'Отклонить'}
                                children={<Button className='btn-danger' icon={<CloseOutlined/>}/>}
                            />
                        </Popconfirm>
                        <Tooltip title={'Детали и переписка'}>
                            <Button className={'btn-secondary'}
                                    icon={<SolutionOutlined/>}
                                    onClick={() => navigate(`match/${record.id}`)}
                            />
                        </Tooltip>
                    </Button.Group>
                    : <Tooltip title={'Детали и переписка'}>
                        <Button className={'btn-secondary'}
                                icon={<SolutionOutlined/>}
                                onClick={() => navigate(`match/${record.id}`)}
                        />
                    </Tooltip>
            })
        } else {
            cols.push({
                key: 'actions',
                width: '10%',
                align: 'center',
                render: (record) => <Tooltip title={'Детали и переписка'}>
                    <Button className={'btn-secondary'}
                            icon={<SolutionOutlined/>}
                            onClick={() => navigate(`match/${record.id}`)}
                    />
                </Tooltip>
            })
        }

        return cols
    }, [role])

    return <Table dataSource={matches} columns={columns}/>
}

export default MatchesStatusesTable