const defaultStages = [
    {stage_name: 'Заключение контракта', status_id: 0, deadline: null, disabled: false, customer_status: null, provider_status: null},
    {stage_name: 'Поставка товара', status_id: 0, deadline: null, disabled: false, customer_status: null, provider_status: null},
    {stage_name: 'Оплата', status_id: 0, deadline: null, disabled: false, customer_status: null, provider_status: null},
    {stage_name: 'Закрытие контракта', status_id: 0, deadline: null, disabled: false, customer_status: null, provider_status: null}
]

function checkDisabled (curIdx, contractStages) {
    if (curIdx === 0) return false

    if (curIdx === 1 || curIdx === 2) {
        return contractStages['Заключение контракта'] ? (!contractStages['Заключение контракта'].is_success) : true
    }

    else if (curIdx === 3) {
        const prevKeys = Object.keys(contractStages)
        const hasPrev = prevKeys.includes('Поставка товара') && prevKeys.includes('Оплата')
        if (!hasPrev) {
            return true
        } else {
            const statuses = []
            for (const [key, value] of Object.entries(contractStages)) {
                if (key === 'Поставка товара') {
                    statuses.push(value?.is_success)
                } else if (key === 'Оплата') {
                    statuses.push(value?.is_success)
                }
            }
            return !statuses.every(i => i);
        }
    }
}

export function prepareStages (contractStages) {

    if (!contractStages) {
        return defaultStages.map((i, idx) => ({...i, disabled: idx > 0}))
    } else {
        return defaultStages.map((stage, idx) => {
            if (contractStages[stage.stage_name]) {
                return {
                    ...stage,
                    ...contractStages[stage.stage_name],
                    completed: !!contractStages[stage.stage_name]?.is_success,
                    disabled: checkDisabled(idx, contractStages)
                }
            } else {
                return {...stage, disabled: checkDisabled(idx, contractStages)}
            }
        })
    }
}