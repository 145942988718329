import {Card, Tabs} from "antd";
import TabList from "../../components/TabList/TabList.jsx";
import {
    $selectedCert,
    $selectedCertSupplier,
    getCertFx,
    SelectedCertGate,
    SelectedCertSupplierGate
} from "../../models/certsModel/index.js";
import {useLocation} from "react-router-dom";
import {useStore} from "effector-react";

const ViewCert = () => {
    const cert = useStore($selectedCert)
    const supplier = useStore($selectedCertSupplier)

    const loading = useStore(getCertFx.pending)

    const tabs = [
        {
            key: 'lot',
            label: 'Информация о сертификате',
            children: <TabList data={cert} dict={'cert'} exclude={['created_at', 'id', 'gov_id']}/>
        },
        {
            key: 'supplier',
            label: 'Информация о поставщике',
            children: <TabList data={supplier} dict={'supplier'} exclude={['id', 'created_at', 'ens_tru']}/>
        }
    ]

    const location = useLocation()

    return <>
        <SelectedCertGate id={parseInt(location.pathname.split('/').at(-1))} />
        <SelectedCertSupplierGate bin={cert?.manufacturer_bin ?? null} />
        <Card title={`Информация о сертификате № ${cert?.cert_id}`} style={{width: '100%'}} loading={loading}>
        <Tabs defaultActiveKey={'lot'} items={tabs} destroyInactiveTabPane={true}/>
    </Card>
    </>
}

export default ViewCert