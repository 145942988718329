import {createEffect} from "effector";
import {NCALayerClient} from "ncalayer-js-client";
import {api} from "../../api/api.js";

// Get token
export const loginFx = createEffect()
    .use(async ({username, password}) => {
        const formdata = new FormData
        formdata.append('grant_type', 'password')
        formdata.append('username', username)
        formdata.append('password', password)

        return (await api(false).post('/token/', formdata)).data
    })
// BIN 131140022032

export const logoutFx = createEffect()
    .use(async () => (await api().post('/token/revoke')).data)

// Local request, without back-end interaction. Getting key from NCA Layer
export const authorizeDSFx = createEffect()
    .use(async () => {
        const client = new NCALayerClient()

        try {
            await client.connect();
        } catch (error) {
            alert(`Не удалось подключиться к NCALayer: ${error.toString()}`);
            return;
        }

        let base64EncodedSignature;
        try {
            base64EncodedSignature = await client.basicsSignCMS(
                NCALayerClient.basicsStoragesAll,
                'MTEK',
                NCALayerClient.basicsCMSParamsDetached,
                NCALayerClient.basicsSignerSignAny
            );
        } catch (error) {
            if (error.canceledByUser) {
                alert('Действие отменено пользователем.');
            }

            alert(error.toString());
            return;
        }

        return base64EncodedSignature;
    }
    )

// Submitting registration with EDS key and password
export const completeRegFx = createEffect()
    .use(async (payload) => {
        return (await api(false).post('/users/', {cert: payload.cert.split('\n').join(''), password: payload.password})).data
    })